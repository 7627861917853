.snippet {
    cursor: pointer;
}

.isSelected {
    opacity: 0.5;
    background-color: rgb(170, 255, 197);
}

.snippetMetaExtra {
    margin-left: auto;
}

.snippetMetaExtra small {
    display: block;
    text-align: right;
}

.snippetMeta {
    display: flex;
}

.root section {
    border: 1px solid #dadce0;
    border-radius: 8px;
    padding: 10px;
    display: block;
    margin-bottom: 15px;
}

.inWizard .editField {
    background-color: white;
    border: 1px dashed #ccc;
    padding: 10px;
}

.inWizard .editField label {
    border: none;
}

.inWizard .editField textarea {
    z-index: 0;
    width: 100% !important;
    min-height: 300px !important;
}
