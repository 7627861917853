.root h5 {
    font-size: 16px;
    color: #001a33;
}

.root {
    padding: 16px !important;
    border: 1px solid #dde0e5;
    border-radius: 10px;
}
.h {
    margin-bottom: 8px;
}


.expandableStyle {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: none;
    background-color: #fafafa;
}