.afterName {
    margin-left: 5px;

    .afterNameIcon {
        color: #10185a !important;
        cursor: default;

        &:hover {
            box-shadow: none;
            background-color: transparent;
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: 30px auto 55px 30px 1fr 30px;
    gap: 10px;

    &:nth-child(n + 2) {
        margin-top: 4px;
    }
}
