.root > .item:not(:last-of-type) .regionName {
    border-bottom: 1px solid #dadada;
    padding-bottom: 4px;
}
.item {
    padding: 4px 0;
}
.region {
    display: flex;
    width: 100%;
    align-items: flex-start;
    cursor: pointer;
}
.expanded {
    margin-left: 20px;
    margin-top: 14px;
    margin-bottom: 20px;
}
.expanded i {
    font-size: 19px;
}
.region i:hover,
.expanded button:hover i {
    box-shadow: 0 0 0 4px rgba(87, 136, 255, 0.2);
    background-color: rgba(87, 136, 255, 0.2);
    border-radius: 50%;
}
.regionName {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-left: 4px;
}

.map {
    position: relative;
}

.map .banner {
    width: calc(100% - 16px);
    padding: 8px;
    position: absolute;
    z-index: 99;
    top: 8px;
    left: 8px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    font-size: 12px;
}

.banner:empty {
    display: none;
}

.banner > div > div {
    line-height: 18px;
}

.banner > div > div:not(:first-child) {
    border-left: 1px solid #dbdce0;
}

.isChecked {
    font-weight: 700;
    color: #5788ff;
}

.banner i {
    vertical-align: middle;
    font-size: 12px;
}
