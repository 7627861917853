.item {
    display: flex;
    align-content: center;
    cursor: pointer;
}

.item:hover {
    box-shadow: 0 2px 17px 0 rgba(182, 185, 205, 0.32);
}

.item img {
    min-height: 100px;
    min-width: 100px;
}

.item > div {
    padding: 14px;
}

.overview {
    max-width: 450px;
    margin: auto;
}
