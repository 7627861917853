.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: red;
}

.animatedWarning {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    line-height: 1;
    height: 30px;
    font-weight: bold;
    color: orange;
    animation: youBrokeIt 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    margin-bottom: 16px;
}

.animatedWarning i {
    font-size: 30px;
    color: orange;
}

@keyframes youBrokeIt {
    0% {
        color: orange;
        font-size: 18px;
    }
    50% {
        color: red;
        font-size: 24px;
    }
    100% {
        color: orange;
        font-size: 18px;
    }
}
