@value primary, primaryBright, text, bg from "@advanza/css/vars.css";

.publicReviews button a,
.publicReviews button a:hover,
.publicReviews button a:focus {
    text-decoration: none;
    color: inherit;
}

.url {
    display: block;
    color: primaryBright;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
}

.renew {
    cursor: pointer;
}
