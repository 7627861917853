.root {
    display: flex;
    flex-wrap: wrap;
}

.item {
    margin-right: 2px;
    border: 1px solid #dadada;
    border-radius: 5px;
    padding: 0 4px;
}

.active {
    background-color: #0b1a37;
    color: #fff;
}

.hideButton {
    margin-left: 10px;
}
.hideButton i {
    font-size: 12px;
}
.item button {
    margin-left: 5px;
}

.item button:hover i {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000;
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.05);
}
.item span {
    max-width: 90px;
    font-size: 11px;
    vertical-align: middle;
    letter-spacing: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.item button.pinned i {
}

.item button.notPinned i {
    opacity: 0.2;
}

.item i {
    font-size: 12px;
    transition: 0.11s ease;
    color: #666;
    border-radius: 50%;
}

.item.active i {
    color: #fff;
}
