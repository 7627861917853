@value primary from '@advanza/css/vars.css';

.root {
    position: relative;
    display: inline-block;
    border-radius: 8px;
    overflow: hidden;
}
.cropButton i {
    color: primary;
}
.cropButton {
    width: 100%;
}
.cropButton button {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
}
.cropButton button:hover i {
    color: #000;
}
.bgImg {
    cursor: pointer;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    justify-content: end;
    overflow: hidden;
}
.image b {
    color: #fff;
    margin: auto;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    padding: 5px 0;
    text-shadow: 0px 0px 4px #000;
}
.image b i {
    color: #fff;
}
