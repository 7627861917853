.dayPicker {
    position: absolute;
    z-index: 99;
    background-color: #fff;
    border: 1px solid #b6b9cd;
    border-radius: 4px;
    margin-top: 12px;
    padding: 14px;
}

.dayPicker.withValue {
    border-color: #10185a;
}

.dayPicker.opened {
    position: relative;
    border-top: none;
    margin: 0;
}
