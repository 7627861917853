.inline {
    display: inline-block;
}

.starIconFull i {
    color: #ffb700;
    font-size: 16px;
    vertical-align: middle;
    display: inline-block;
    width: 1em;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.alt .starIconFull i {
    color: #5788ff;
}

.starIconEmpty i {
    color: hsla(43.1, 100%, 50%, 0.4);
    font-size: 16px;
    vertical-align: middle;
    display: inline-block;
    width: 1em;
}

.alt .starIconEmpty i {
    color: rgb(207, 222, 255);
}

.nrReviews {
    color: rgba(0, 0, 0, 0.56);
    font-size: 14px;
}

.newStyle i {
    color: #5788ff;
    font-size: 23px;
}

.newStyle .starIconEmpty i {
    opacity: 0.2;
}
