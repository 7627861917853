.toggle.inline {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggle label {
    cursor: pointer;
}
.toggle input {
    display: none;
}

.legend {
    font-family: Mulish, sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    margin: 2px 0 4px;
}

.toggle.inline .legend {
    margin-right: 8px;
}

.toggleOff,
.toggleOff i {
    color: #f42435;
}

.toggle label:hover i {
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.22);
}
.toggleOn,
.toggleOn i {
    color: #006f44;
}
