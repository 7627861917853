.inActive {
    text-decoration: line-through;

    & > div:last-of-type {
        visibility: hidden;
    }
}

.instructions {
    ul {
        padding-left: 20px;

        li {
            list-style: disc;
        }
    }
}

.freeTrialIsActive {
    color: rgb(12, 214, 5);
    font-weight: bold;
}
