.grid {
    display: grid;
    grid-template-areas:
        'header header header'
        'list results options';
    grid-gap: 24px;
    grid-auto-columns: auto auto auto;
    position: relative;
    margin-top: 16px;
}

.weekEstimation {
    text-align: center;
}
.weekEstimation text {
    fill: #202124 !important;
}

.weekEstimation > div {
    margin: auto;
}

.weekEstimation h4 {
    margin-top: 14px;
}

.summary {
    margin-top: 24px;
}

.map {
    grid-area: map;
}

.header {
    grid-area: header;
}

.results {
    grid-area: results;
}

.list {
    grid-area: list;
}

.options {
    grid-area: options;
}

.loading {
    pointer-events: none;
    opacity: 0.5;
}

.header {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    grid-gap: 16px;
}
.header h1 {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    align-items: center;
}
.results {
    max-width: 550px;
    margin: auto;
    margin-top: 0;
}

.controls {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 16px;
}
