.loadBox {
    transition: opacity 0.4s ease;
    padding: 0 14px;
    position: relative;
}

.loading {
    opacity: 0.3;
}

.loading::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
