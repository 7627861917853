.header {
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
}

.title {
    flex-wrap: nowrap;
    cursor: pointer;
    padding: 16px 0 8px;
}

.titleText {
    font-size: 16px;
}

.arrow {
    border: solid;
    transition: transform 0.2s ease;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(-45deg);
    padding: 4px;

    &.upDownArrows {
        transform: rotate(45deg) translate(-2px, -2px);
    }
}

.open .arrow {
    transform: rotate(45deg);

    &.upDownArrows {
        transform: rotate(-135deg) translate(-2px, -2px);
    }
}

.content {
    overflow: hidden;
    max-height: 0;
    height: 100%;
    transition: max-height 0.2s ease, padding 0.2s ease;
}

.open .content {
    padding: 8px 0;
}

.appearance_bold {
    .titleText {
        font-size: 14px;
        font-family: Poppins, sans-serif;
        font-weight: bold;
    }
}

.appearance_border {
    .title {
        border-bottom: 1px solid #dadada;
    }
}

.appearance_blocks {
    .title {
        padding: 0;
    }

    .content {
        padding-left: 24px;
        margin: 0;
        font-size: 12px;
        line-height: normal;
    }

    .content::before {
        content: '';
        display: block;
        height: 7px;
        width: 100%;
    }
}
