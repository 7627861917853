.header,
.body {
    padding-bottom: 16px;
}

.alt1 {
    background-color: transparent;
}

.alt1 .modalInside {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #c0c1c7;
    border-radius: 4px;
    max-width: 328px;
}

.alt1 .closeButton button {
    padding-top: 6px;
    padding-bottom: 6px;
}

.alt1 .closeButton i {
    color: #10185a;
}

.alt1 .content {
    padding: 0;
}

.alt1 .header {
    font-size: 14px;
    padding: 8px 40px;
    border-bottom: 1px solid #b7bace;
    text-align: center;
}

.alt1 .body {
    padding: 10px 16px 0;
}

.alt1 .buttons {
    padding: 16px;
}
