.table td {
    background-color: #fff;
    padding: 6px 0;
    cursor: pointer;
}


@media (min-width: 1200px) {
    .showHiddenFilterLink {
        margin-left: auto;
    }
}
