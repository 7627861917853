.avatar {
    background-color: #dadada;
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}
.img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    opacity: 0.5;
    top: 0;
}
.icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto;
    text-align: center;
}
.icon i {
    margin: auto;
    font-size: 40px;
    color: #fff;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}
