/* Specific styling for the ReactDayPicker*/
/* https://react-day-picker.js.org/basics/styling */

.vhidden {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
}

.months {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.caption {
    position: relative;
}

.caption_label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #10185a;
}

.nav {
    display: flex;
    justify-content: space-between;
}

.caption_end:not(.caption_start) .nav {
    justify-content: flex-end;
}

.nav button {
    height: 40px;
    width: 40px;
    background: #c4dcff;
    border-radius: 5px;
}

.nav button svg {
    width: 12px;
    height: 12px;
}

.table,
.table thead,
.table tbody,
.table th,
.table tr,
.table td {
    margin: 0;
    padding: 0;
    border: none;
}

.table {
    border-collapse: separate;
    border-spacing: 7.33px;
    margin: 0 -7.33px;
    max-width: 324px;
}

.head_cell {
    color: #aaaab0;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
}

.day {
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 26px;
    background-color: #eff8ff;
    border-radius: 5px;
}

.day:not(.day_disabled):hover {
    border: 1px solid #5788ff;
}

.day.day_selected,
.day.day_selected:not(.day_disabled):hover {
    background-color: #5788ff;
}

.day_disabled {
    background-color: transparent;
    color: #656a6e;
    cursor: not-allowed;
}

@media (max-width: 389px) {
    .day {
        width: 30px;
        height: 30px;
    }
}
