.table {
    thead {
        border-bottom: 2px solid #10185a;

        th {
            font-weight: bold;
            color: #10185a;
            text-wrap: nowrap;
            text-align: center;
        }
    }

    tbody {
        tr {
            &:nth-child(n + 2) {
                border-top: 1px solid rgba(0, 0, 0, 0.05);
            }

            td {
                border-right: 1px solid rgba(0, 0, 0, 0.05);
                text-align: center;
                padding: 5px !important;

                &:first-child {
                    border-right: 2px solid #10185a;
                    text-wrap: nowrap;
                    text-align: left;
                }
            }
        }
    }
}
