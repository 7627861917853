$defaultSize: 10px;
$toScreenBorderSmall: 16px;
$toScreenBorderLarge: 24px;

.wrapper {
    overflow: hidden;
    position: relative;
    padding: 13px $defaultSize;
    margin: -13px -1 * $defaultSize;
}

.relative {
    position: relative;
}

.list {
    transform: translateX(0%);
    transition-property: transform;
    width: calc(100% + 10px);
    display: flex;
    position: relative;
    list-style-type: none;
    margin: 0 0 1px 0;
    padding: 0;
}

.newStyle .list {
    width: calc(100% + 16px);
}

.list li {
    position: relative;
}

.item {
    flex: none;
}

.prev {
    left: -16px;
}

.next {
    right: -16px;
}

.next,
.prev {
    position: absolute;
    z-index: 2;
    align-items: center;
    height: 100%;
    display: flex;
}

.bubbles {
    position: absolute;
    bottom: 21px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    z-index: 2;
    max-width: 150px;
    margin: auto;
    left: 0;
    right: 0;
}

.bubbles > div {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-right: 1px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
}

.bubbles > div.current {
    background-color: #5788ff;
}

.next i,
.prev i {
    opacity: 1;
    transition: 0.2s ease;
    background-color: #fff;
    width: 40px;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    -webkit-transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    transform: rotate(0.03deg);
}

.blueButtons .next i,
.blueButtons .prev i {
    background-color: #c4dcff;
    border-radius: 5px;
}

.next:hover i,
.prev:hover i {
    color: #000;
    cursor: pointer;
}

.useTouchScroll .list {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px 20px;
    margin: -20px -20px !important;
}

@media (pointer: coarse) {
    .useTouchMobile .wrapper.useSmallOverflow {
        padding-left: $toScreenBorderSmall;
        padding-right: $toScreenBorderSmall;
        margin-left: -1 * $toScreenBorderSmall;
        margin-right: -1 * $toScreenBorderSmall;

        .list {
            width: calc(100% + 2 * $toScreenBorderSmall);
        }
    }

    .useTouchMobile .wrapper.useLargeOverflow {
        padding-left: $toScreenBorderLarge;
        padding-right: $toScreenBorderLarge;
        margin-left: -1 * $toScreenBorderLarge;
        margin-right: -1 * $toScreenBorderLarge;

        .list {
            width: calc(100% + 2 * $toScreenBorderLarge);
        }
    }

    .useTouchMobile .list {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding: 20px 20px;
        margin: -20px -20px !important;
    }

    .useTouchMobile:not(.useTouchScroll) .list > li:first-of-type {
        display: none;
    }

    .useTouchMobile .next,
    .useTouchMobile .prev {
        display: none;
    }

    .prev i,
    .next i {
        font-weight: bold;
        background-color: transparent;
        box-shadow: none;
    }

    .prev {
        left: 0px;
    }

    .next {
        right: 0px;
    }
}

.newStyle .prev i,
.newStyle .next i {
    color: #10185a;
}

@media (min-width: 767px) {
    .bubbles {
        display: none;
    }
}
@media (min-width: 1300px) {
    .newStyle .prev {
        left: -40px;
    }

    .newStyle .next {
        right: -40px;
    }

    .newStyle .prev i,
    .newStyle .next i {
        box-shadow: none;
    }
}

@media (min-width: 1528px) {
    /* Calculation: 1440px max-width container - 2*16px padding + 2*60px arrow shift */
    .bigScreenNavigation .next {
        right: -60px;
    }

    .bigScreenNavigation .prev {
        left: -60px;
    }
}
