.row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
}
.row > div:hover {
    cursor: pointer;
    font-weight: bold;
}
.selected {
    font-weight: bold;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background: #eee;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    border: 0;
    background: #5788ff;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 19px;
    height: 19px;
    border: 0;
    border-radius: 50%;
    background: #5788ff;
    cursor: pointer;
}
