.root {
    display: flex;
    align-items: center;
}
.root > *:not(:last-of-type) {
    margin-right: 14px;
}
.root > *:nth-of-type(2) {
    flex-grow: 1;
}
