.root {
    position: relative;
}
.root section {
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #dadce0;
    margin-bottom: 10px;
}
section.saved {
    background-color: rgba(0, 255, 10, 0.07);
    border-color: rgba(1, 130, 6, 0.19);
}
