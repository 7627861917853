.profileLabelWrapper {
    display: inline-block;
}

.profileLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    margin: 4px 0;
    border-radius: 2px;
    line-height: 1;
}

.profileLabel i {
    color: inherit;
    margin-right: 3px;
    transform: none !important;
    overflow: visible;
}

.profileLabel span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.warning {
    display: inline-block;
    color: #f00;
}

.warning i {
    max-width: 1em;
}
