.root {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        gap: 8px;
        cursor: pointer;

        .icon {
            padding-right: 8px;
        }

        &.disabled {
            cursor: not-allowed;

            .icon {
                color: grey;
            }
        }
    }

    &.bottomToggle {
        flex-direction: column-reverse;

        .title {
            flex-direction: row-reverse;
            justify-content: center;
            margin-top: 12px;
        }
    }

    .body {
        overflow: hidden;
        max-height: 0px;
        height: 100%;
        transition: max-height 0.2s ease, padding 0.2s ease;
        margin: 0;

        &.isOpened {
            overflow: visible;
        }
    }
}
