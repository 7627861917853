@value border, grey-700, textLight, primary, primaryBright, text from './vars.css';

@value focusColor: #10185a;

.hidePlaceholder.focused .placeholder,
.hidePlaceholder .input:focus + .placeholder {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    background-color: transparent;
    color: #b6b9cd;
}

.hidePlaceholder.focused.notEmpty .placeholder,
.hidePlaceholder.notEmpty .input:focus + .placeholder,
.select.hidePlaceholder.focused .placeholder,
.select.hidePlaceholder select:focus + .placeholder {
    display: none;
}

.label {
    position: relative;
    font-size: 16px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    height: 100%;
    background-color: #fff;
    border: 1px solid #b6b9cd;
}
.label input:disabled {
    color: #b6b9cd;
    cursor: not-allowed;
}
.label input:disabled + .placeholder {
    color: #b6b9cd;
}

.label > * {
    display: inline-block;
}

.before {
    padding-left: 8px;
}

.after {
    padding-right: 14px;
}

.after.withIconStyle i {
    color: #fff !important;
    background-color: #5788ff;
    border-radius: 50%;
    font-size: 11px;
    padding: 2px;
    max-width: none;
}

.root textarea::placeholder,
.root input:-moz-placeholder {
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: #b6b9cd;
    opacity: 1;
}

.root textarea::placeholder,
.root input::-webkit-input-placeholder {
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: #b6b9cd;
    opacity: 1;
}

.root textarea::placeholder,
.root input::placeholder {
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: #b6b9cd;
    opacity: 1;
}

textarea.input {
    min-height: 105px;
}

textarea + .placeholder {
    top: 10px;
}

.root:not(.focused) textarea + .placeholder {
    overflow: visible;
    white-space: normal;
}

.label .input,
.datePicker input {
    color: primary;
    width: 100%;
    padding: 12px 14px;
    -webkit-transition: border 0.14s ease;
    -moz-transition: border 0.14s ease;
    -ms-transition: border 0.14s ease;
    -o-transition: border 0.14s ease;
    transition: border 0.14s ease;
    box-sizing: border-box;
    border: none;
    line-height: 16px;
    height: 44px;
    min-width: 1px;
    border-radius: 4px;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.compact .label .input,
.compact .datePicker input {
    height: 38px;
    padding: 11px 14px;
}

.hasLegend .label .input {
    background-color: #fff;
}

.datePickerRoot {
    color: red;
}

.placeholder {
    position: absolute;
    left: 8px;
    pointer-events: none;
    -webkit-transition: transform 0.14s ease;
    -moz-transition: transform 0.14s ease;
    -ms-transition: transform 0.14s ease;
    -o-transition: transform 0.14s ease;
    transition: transform 0.14s ease;
    padding: 0 8px;
    transform-origin: bottom left;
    background-color: #fff;
    top: 0.5em;
    text-align: left;
    right: 1px;
    color: rgb(128, 134, 139);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    z-index: 10;
}

.focused.valueFocus label,
.focused.activeFocus label,
.input:focus label {
    border-color: focusColor;
}

.validBorder label {
    border-color: #5788ff;
}

.focused .datePicker input,
.datePicker input:focus {
    border-color: focusColor;
}

.focused .placeholder,
.input:focus + .placeholder {
    -moz-transform: scale(0.75) translateY(-34px);
    transform: scale(0.75) translateY(-34px);
    color: focusColor;
    right: auto;
}

.compact.focused .placeholder,
.compact .input:focus + .placeholder {
    -moz-transform: scale(0.75) translateY(-31px);
    transform: scale(0.75) translateY(-31px);
}

.legend {
    font-family: Mulish, sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: primary;
    margin: 2px 0 4px;
}

.error {
    color: red;
    font-weight: bold;
}

.errorPreIcon {
    color: red;
}

.error i,
.errorPreIcon i {
    color: red !important;
}

.hasError label {
    border-color: red !important;
}

.hasError.focused .placeholder {
    color: red;
}

.newStyle .label {
    font-size: 14px;
}

/*SELECT*/

.select .label {
    display: block;
}

.select .input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: transparent;
    margin-right: 16px;
    padding: 8px 16px 8px 12px;
}

.newStyle.select .input {
    padding-right: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.select .placeholder {
    margin-right: 16px;
}

.focused.select .input,
.select .input:focus {
    color: inherit;
}

.select .input:focus {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.select .input option {
    padding: 12px 16px;
}

.selectTriangle {
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgb(128, 134, 139);
    transform: translateY(-50%);
    z-index: 11;
}

.selectTriangleFixZ {
    z-index: auto;
}

.input:focus ~ .selectTriangle {
    border-top: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgb(128, 134, 139);
}

.newStyle .selectTriangle,
.newStyle .input:focus ~ .selectTriangle {
    border: none;
    transform: none;
    right: 27px;
    top: 12px;
    font-size: 21px;
}

label.noDropdown {
    height: 44px;
    padding: 8px 16px;
}

.select.newStyle.noBorder .label {
    border: none;
    background-color: transparent;
}

.select.newStyle.noBorder .input {
    background-color: transparent;
    margin: 0;
    padding: 0 21px 0 0;
    font-size: 14px;
    height: 21px;
    text-align: right;
}

.select.newStyle.noBorder .input option {
    padding: 0;
}

.select.newStyle.noBorder .selectTriangle {
    right: 16px;
    top: 0;
}

/*TOGGLE*/
.toggle input {
    display: none;
}

.toggle label {
    display: inline-block;
    border: 1px solid primaryBright;
    border-radius: 5px;
}

.toggle label > div {
    width: 47px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

@media (min-width: 768px) {
    .toggle label > div {
        width: 4em;
    }
}

.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    color: textLight;
    transform: scaleX(0);
    transition: transform 0.19s ease;
}

.toggleOn > div:first-of-type .bg {
    background-color: primaryBright;
    transform-origin: top right;
    transform: scaleX(1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.toggleOn > div:first-of-type span {
    color: #fff;
}

.toggleOff > div:last-of-type span {
    color: #fff;
}

.toggleOff > div:last-of-type .bg {
    background-color: rgb(210, 70, 70);
    transform-origin: top left;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transform: scaleX(1);
}

.toggle span {
    position: relative;
    transition: color 0.2s ease;
    z-index: 1;
    color: rgba(81, 81, 81, 0.56);
}

/*CHECKBOX*/
.checkbox input {
    display: none;
}

.checkbox label {
    cursor: pointer;
    padding: 6px 0;
}

.checkbox i {
    color: grey-700;
}

.checkbox .label:hover .icon,
.checkbox .label:hover i {
    border-color: #000;
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.05);
    transition: 0.11s ease;
    border-radius: 50%;
}

.label:hover .icon:after {
    background-color: #000;
}

.checked i {
    color: primaryBright;
}
