.rootRow {
    flex-wrap: nowrap;
}

.cropButtonCol {
    padding-right: 0;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cropButton i {
    color: #5788ff;
}

.cropButton:hover i {
    color: #000;
}

.content {
    text-align: center;
}
