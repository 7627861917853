.root {
    transition: all 0.1s ease;
}

.orderChanged {
    outline: 2px solid #1a73e8;
}

.optionStatus {
    min-width: 150px;
}

.buttonRow > div {
    padding-top: 4px;
    padding-bottom: 4px;
}
