@value primary,textLight,text from './vars.css';

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden],
template {
    display: none;
}
html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
}
a {
    background: 0 0;
    text-decoration: none;
    padding: 0;
    font-weight: normal;
    color: inherit;
}
a:focus {
    outline: 0;
}
a:active,
a:hover {
    outline: 0;
}
h1 {
    margin: 0.67em 0;
    font-size: 32px;
}
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: 700;
}
dfn {
    font-style: italic;
}
hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}
mark {
    background: #ff0;
    color: #000;
}
code,
kbd,
pre,
samp {
    font: 1em monospace, serif;
}
q {
    quotes: '\201C''\201D''\2018''\2019';
}
small {
    font-size: 80%;
    line-height: 1.2;
    display: inline-block;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
img {
    border: 0;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}
fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0;
    padding: 0;
}
button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}
button,
input {
    line-height: normal;
}
button,
select {
    text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}
input[type='search'] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font: 400 14px/1.4rem Mulish, 'Work Sans', Helvetica, Arial, serif;
    background-color: #f2f2f2;
    overflow-x: hidden;
    height: 100%;
    color: #10185a;
    line-height: 21px;
}
:focus {
    outline: none transparent;
}
h2 + p {
    margin: 0 0 2rem;
}
a:hover {
    color: inherit;
}
pre {
    overflow-x: auto;
    padding: 1.25em;
    border: 1px solid #e6e6e6;
    border-left-width: 5px;
    margin: 1.6em 0;
    font-size: 0.875em;
    background: #fcfcfc;
    white-space: pre;
    word-wrap: normal;
}
code {
    color: #007fff;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
#root {
    height: 100%;
}
h3 {
    font-size: 14px;
}
h4 {
    font-size: 14px;
    font-weight: 500;
}
h5 {
    font-size: 14px;
    font-weight: 500;
    color: textLight;
}
button {
    background-color: transparent;
    border: none;
    font-family: inherit;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
}
p {
    line-height: 1.5;
}
@media (pointer: coarse) {
    * {
        -webkit-tap-highlight-color: transparent;
    }
}
:global(.poppins),
h1,
:global(.h1),
:global(.h2),
h2,
h3,
h4,
h5,
h6 {
    font-family: Poppins, "'Work Sans'", Helvetica, Arial, serif;
    color: primary;
    font-weight: bold;
    margin: 0;
    line-height: normal;
}
:global(.poppins) {
    line-height: 1.25;
}
h1,
:global(.h1) {
    font-size: 24px;
    line-height: 1.3;
}
h2,
:global(.h2) {
    font-size: 18px;
    line-height: 24px;
}
:global(.mulish) {
    font: 400 14px/21px Mulish, 'Work Sans', Helvetica, Arial, serif;
}
a.a {
    color: primary;
}
@media (display-mode: standalone) {
    body {
        background-color: #fff;
    }
    html {
        overscroll-behavior: none;
    }
}
