@keyframes highlight {
    from {
        color: transparent;
    }

    to {
        color: inherit;
    }
}

.highlight::after {
    content: ' !!!';
    animation-duration: 1s;
    animation-name: highlight;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
