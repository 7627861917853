.padding {
    padding: 0 16px;
}

@media (min-width: 768px) {
    .padding {
        padding: 0 40px;
    }
    .padding.hasScrollBar:not(.hideScrollBar) {
        padding-right: 32px; /* 8px scrollbar width */
    }
}

.header,
.scrollable {
    background-color: #f7f7f7;
}

.header.dark {
    background-color: #10185a;
    color: #fff;
}

.header.light {
    background-color: #5788ff;
    color: #fff;
}

.header.transparent {
    border-bottom: 1px solid #dbdce0;
}

.heading {
    font-size: 18px;
    line-height: 21px;
    font-family: Poppins, sans-serif;
    color: #10185a;
    font-weight: bold;
    margin: 0;
}

.header.dark .heading,
.header.light .heading {
    color: #fff;
}

.scrollable {
    flex: 1 1 auto;
    overflow-y: auto;
    scrollbar-width: thin; /* scroll firefox */
}

.scrollable.hideScrollBar {
    overflow-y: hidden;
}

.scrollable::-webkit-scrollbar {
    width: 8px;
    background-color: #f0f0f0;
}

.scrollable:hover::-webkit-scrollbar-thumb:vertical {
    background-color: #cdcdcd;
}

.footer {
    background-color: #fff;
    border-top: 1px solid #dbdce0;
    margin-top: auto;
}

@media (max-width: 767px) {
    .footer {
        margin-top: auto;
    }
}
