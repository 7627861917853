.box {
    border: 1px solid #ccc;
    padding: 15px;
    background-color: #fafafa;
    position: relative;
}

.indent {
    padding-left: 15px;
}
