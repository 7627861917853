@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

* {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.row.np {
    padding-left: 0;
    padding-right: 0;
}

.row.sm {
    margin-left: -5px;
    margin-right: -5px;
    max-width: none;
}

.row.sm > .col,
.row.sm > .col-1,
.row.sm > .col-2,
.row.sm > .col-3,
.row.sm > .col-4,
.row.sm > .col-5,
.row.sm > .col-6,
.row.sm > .col-7,
.row.sm > .col-8,
.row.sm > .col-9,
.row.sm > .col-10,
.row.sm > .col-11,
.row.sm > .col-12 {
    padding-left: 5px;
    padding-right: 5px;
}

.row.nm {
    margin-left: 0;
    margin-right: 0;
}

.row.no-wrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters .col,
.row.no-gutters [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col.np,
.col-1.np,
.col-2.np,
.col-3.np,
.col-4.np,
.col-5.np,
.col-6.np,
.col-7.np,
.col-8.np,
.col-9.np,
.col-10.np,
.col-11.np,
.col-12.np {
    padding-left: 0;
    padding-right: 0;
}

.col {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
}

.col-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
}

.col-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.area.sp {
    padding-top: 33px;
    padding-bottom: 33px;
}

@media (min-width: 576px) {
    .area.sp {
        padding-top: 90px;
        padding-bottom: 90px;
    }
}

.area.off-x {
    padding-left: 20px;
    padding-right: 20px;
}

.area.off-x-min {
    padding-left: 10px;
    padding-right: 10px;
}

.area.off-x-plus {
    padding-left: 30px;
    padding-right: 30px;
}

.area.off-x-mega {
    padding-left: 40px;
    padding-right: 40px;
}

.area.off-y {
    padding-top: 20px;
    padding-bottom: 20px;
}

.area.off-y-min {
    padding-top: 10px;
    padding-bottom: 10px;
}

.area.off-y-plus {
    padding-top: 30px;
    padding-bottom: 30px;
}

.area.off-y-mega {
    padding-top: 40px;
    padding-bottom: 40px;
}

.area.off-s {
    padding-bottom: 20px;
}

.area.off-s-min {
    padding-bottom: 10px;
}

.area.off-s-plus {
    padding-bottom: 30px;
}

.area.off-s-mega {
    padding-bottom: 40px;
}

.area.off-w {
    padding-left: 20px;
}

.area.off-w-min {
    padding-left: 10px;
}

.area.off-w-plus {
    padding-left: 30px;
}

.area.off-w-mega {
    padding-left: 40px;
}

.area.off-n {
    padding-top: 20px;
}

.area.off-n-min {
    padding-top: 10px;
}

.area.off-n-plus {
    padding-top: 30px;
}

.area.off-n-mega {
    padding-top: 40px;
}

.area.off-e {
    padding-right: 20px;
}

.area.off-e-min {
    padding-right: 10px;
}

.area.off-e-plus {
    padding-right: 30px;
}

.area.off-e-mega {
    padding-right: 40px;
}

.area.card {
    border-radius: 7px;
    overflow: hidden;
}

.area.b-grey {
    border: 1px solid #dadce0;
}

.bg-blue {
    background-color: #bde8ff;
}

.bg-light-blue {
    background-color: #e3f0fe;
}

.bg-magenta {
    background-color: #ffd6d6;
}

.bg-orange {
    background-color: #ffd5b3;
}

.bg-green {
    background-color: #b8ffad;
}

.bg-black {
    background-color: #000;
}

.bg-grey {
    background-color: rgba(243, 244, 246, 0.5);
}

.bg-msg-grey {
    background-color: #f1f2f3;
}

.bg-purp-grey {
    background-color: #dadce0;
}

.bg-white {
    background-color: #fff;
}

.container {
    position: relative;
    max-width: 1170px;
    margin: auto;
}

.to-right {
    margin-right: 0;
    margin-left: auto;
}

.justify,
.row.justify {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.evenly {
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.between {
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.j-center {
    -webkit-justify-content: center;
    justify-content: center;
}

.row.reverse {
    -ms-flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.grow {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.no-wrap {
    -webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.y-ctr {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pos-rel {
    position: relative;
}

.center {
    justify-content: space-around;
    text-align: center;
}

.tright {
    text-align: right;
    justify-content: flex-end;
}

.self-center {
    -webkit-align-self: center;
    align-self: center;
}

.tleft {
    text-align: left;
}

.y-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.to-center {
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
}

.v-center {
    align-items: center;
    vertical-align: middle;
}

.v-bottom {
    align-items: flex-end;
    vertical-align: bottom;
}

.self-bottom {
    -webkit-align-self: end;
    align-self: end;
}

.v-top {
    align-items: flex-start;
    vertical-align: top;
}

@media (max-width: 1025px) {
    .hide-tablet {
        display: none;
    }
}

@media (min-width: 1025px) {
    .hide-desktop {
        display: none;
    }
}

input,
textarea,
select {
    position: relative;
    z-index: 10;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: transparent;
    padding: 5px 0;
    font: 16px 'Work Sans', Serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 2em;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: 0;
}

input::-webkit-input-placeholder,
input:-moz-placeholder,
input:-ms-input-placeholder,
input::placeholder,
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder,
select::-webkit-input-placeholder,
select:-moz-placeholder,
select:-ms-input-placeholder,
select::placeholder {
    color: rgba(0, 0, 0, 0.53);
    font-size: 16px;
}

textarea,
input[type='text'],
input[type='submit'],
input[type='password'],
[type='email'] {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

textarea {
    width: 100%;
    font-family: inherit;
}

.textarea {
    position: relative;
}

.textarea textarea {
    display: block;
    min-height: 90px;
    padding-top: 10px;
}

.textarea .label-text {
    max-height: 40px;
    position: absolute;
    top: 10px;
}

.material-label.error input {
    border-bottom: 2px solid #ee386d;
}

.material-label .error {
    color: #ff8c28;
    font-weight: 700;
    font-size: 13px;
}

.login-input {
    background-color: #fff;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    border: 4px solid transparent;
}

.login-input input {
    padding: 0 15px 15px !important;
}

.login-input > i {
    max-width: 20px;
}

.material-input {
    width: 100%;
}

.material-input input {
    width: 100%;
    -webkit-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
}

.material-input.password input {
    width: calc(100% - 30px);
}

.material-input.password .material-label > i {
    display: inline-block;
    vertical-align: sub;
}

.material-input.has-error .material-label {
    border-color: #ee386d !important;
    border-bottom-width: 2px;
}

.material-input.has-error .material-label:before {
    background-color: #ee386d !important;
}

.material-input .error {
    color: #ee386d;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    line-height: 1;
}

.material-input .icon {
    position: absolute;
    top: auto;
    bottom: auto;
    z-index: 33;
    font-size: 21px;
    color: #b4b4b4;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.material-input .icon i {
    font-size: inherit;
}

.material-input.with-icon .material-label input {
    padding-left: 25px;
}

.material-input.with-icon .material-label .label-text {
    left: 25px;
}

.material-input.with-icon.input-date .material-label input {
    padding-left: 45px;
}

.ie .material-label .icon {
    top: 10px;
}

.material-input .material-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: left;
    border: none;
    padding: 0;
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid #dadce0;
}

.material-input .material-label .label-text {
    vertical-align: middle;
    font-weight: 400;
    transform-origin: bottom left;
    -webkit-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    color: rgba(0, 0, 0, 0.53);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    max-width: 100%;
    height: 1.2em;
    line-height: 1.3;
    position: absolute;
    bottom: calc(50% - 0.7em);
    cursor: text;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    max-width: calc(100% - 20px);
    line-height: 1;
}

.material-input .material-label.special {
    border: 1px solid #000;
}

.material-input .material-label.jump.up {
    border-color: #222;
}

.material-input .material-label.jump.up input,
.material-input .material-label.jump.up textarea {
    border-bottom-color: #2862c2;
}

.material-input .material-label.jump.up:before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    background-color: #222;
}

.material-input .material-label.jump.up .label-text {
    opacity: 1;
    padding-bottom: 5px;
    -webkit-transform: translate3d(0px, -1.5em, 0) scale(0.75);
    -moz-transform: translate3d(0px, -1.5em, 0) scale(0.75);
    -ms-transform: translate3d(0px, -1.5em, 0) scale(0.75);
    -o-transform: translate3d(0px, -1.5em, 0) scale(0.75);
    transform: translate3d(0px, -1.5em, 0) scale(0.75);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    max-width: 120%;
    left: 0;
}

.material-input .material-label.up .label-text {
    opacity: 0;
}

.material-input .material-label.inline {
    display: inline-block;
}

.material-input .material-label.inline > * {
    display: inline-block;
    vertical-align: middle;
    width: auto;
}

label > :first-child + .switch {
    margin-top: 5px;
}

.switch {
    cursor: pointer;
    -webkit-transition: 0.16s cubic-bezier(0.77, 0.01, 0.35, 1.05);
    -moz-transition: 0.16s cubic-bezier(0.77, 0.01, 0.35, 1.05);
    -o-transition: 0.16s cubic-bezier(0.77, 0.01, 0.35, 1.05);
    transition: 0.16s cubic-bezier(0.77, 0.01, 0.35, 1.05);
    width: 40px;
    display: block;
    height: 15px;
    border-radius: 15px;
    background-color: rgba(190, 190, 193, 0.4);
}

.switch input {
    display: none;
}

.switch .switch-button {
    background-color: #bebec1;
    -webkit-transition: 0.16s ease;
    -moz-transition: 0.16s ease;
    -ms-transition: 0.16s ease;
    -o-transition: 0.16s ease;
    transition: 0.16s ease;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    position: relative;
    bottom: 3px;
}

.switch.on {
    background-color: #bbdca2;
}

.switch.on .switch-button {
    background-color: #86c25e;
    -webkit-transform: translateX(18px);
    -moz-transform: translateX(18px);
    -ms-transform: translateX(18px);
    -o-transform: translateX(18px);
    transform: translateX(18px);
}

.input-file label > span {
    -webkit-transform: translate3d(0px, -1.5em, 0) scale(0.75);
    -moz-transform: translate3d(0px, -1.5em, 0) scale(0.75);
    -ms-transform: translate3d(0px, -1.5em, 0) scale(0.75);
    -o-transform: translate3d(0px, -1.5em, 0) scale(0.75);
    transform: translate3d(0px, -1.5em, 0) scale(0.75);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    max-width: 120%;
    left: 0;
}

.input-file .btn span:before {
    font-family: "'Material Symbols Outlined'!important", sans-serif;
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
    text-transform: none;
    content: 'note_add';
    padding-right: 10px;
}

.input-file input {
    display: none;
}

ul.files li {
    padding: 15px 5px;
}

ul.files li:not(.no-delete):before {
    font-family: "'Material Symbols Outlined'!important", sans-serif;
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
    text-transform: none;
    content: 'close';
}

.input-search input {
    background-color: #fff;
    width: 100%;
}

.hdpi.pac-logo::after {
    display: none !important;
}

.pac-container,
.autocomplete-container {
    -webkit-box-shadow: 0 0px 10px rgba(32, 33, 36, 0.19), 0 3px 30px rgba(32, 33, 36, 0.14);
    -moz-box-shadow: 0 0px 10px rgba(32, 33, 36, 0.19), 0 3px 30px rgba(32, 33, 36, 0.14);
    box-shadow: 0 0px 10px rgba(32, 33, 36, 0.19), 0 3px 30px rgba(32, 33, 36, 0.14);
    pointer-events: visible;
    border: 1px solid #dadce0;
    border-top: 0;
}

.pac-container .selected,
.autocomplete-container .selected {
    background-color: #f5f7f6;
}

.pac-item {
    padding-top: 3px;
    padding-bottom: 3px;
}

.pac-item .pac-icon {
    background-image: none;
    z-index: 999;
    position: relative;
    width: 20px;
    margin: 0;
}

.pac-item .pac-icon:before {
    position: absolute;
    font-family: "'Material Symbols Outlined'!important", sans-serif;
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
    text-transform: none;
    padding-right: 7px;
    content: 'place';
}

.quote-suggested-location {
    padding: 0px 0px 10px;
    border-radius: 3px;
    border: 1px solid #bdbdbd;
    border-top: 0;
}

.autocomplete-container {
    position: absolute;
    background-color: #fff;
    width: 100%;
    z-index: 103;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
}

.autocomplete-container ul > li {
    cursor: default;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid #ddd;
    height: 50px;
}

.autocomplete-container ul > li.ac-result.label {
    font-size: 13px;
    color: #646a6e;
    background-color: #f5f7f6;
    width: 100%;
    border-radius: 0;
    height: auto;
    margin: 0;
    padding: 10px 25px;
}

.autocomplete-container ul > li:hover {
    background-color: #f5f7f6;
}

.autocomplete-container ul > li > * {
    padding-right: 1em;
}

.autocomplete-container ul > li small {
    color: #646a6e;
}

.autocomplete-container ul > li .i {
    font-size: 10px;
    padding: 0;
}

.autocomplete-container ul > li > .col-10,
.autocomplete-container ul > li > .col-12 {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
}

.autocomplete-container ul > li > .col-10 b,
.autocomplete-container ul > li > .col-12 b {
    padding-right: 1em;
}

.autocomplete-container ul > li > .col-2 {
    max-width: 20px;
    padding: 0 15px;
}

.autocomplete-container ul > li > .col-2 i {
    color: #bdbdbd;
}

.autocomplete-container ul > li > .col-10 {
    text-align: left;
}

.new-g-style.with-icon .material-label .icon {
    left: 15px;
}

.new-g-style.with-icon .material-label .label-text {
    left: 45px;
    max-width: calc(100% - 45px);
}

.new-g-style.with-icon .material-label input {
    padding-left: 25px;
}

.new-g-style .material-label {
    padding-left: 20px;
    height: 46px;
    height: 36px;
    border: 1px solid #dadce0;
    border-radius: 9px;
}

.new-g-style .material-label input {
    height: 100%;
}

.new-g-style .material-label.focus {
    background-color: #fff;
    border-color: #1f5a9b;
}

.new-g-style.textarea .material-label {
    height: 100%;
    min-height: 46px;
}

.new-g-style .open .material-label {
    background-color: #fff;
    border-color: #1f5a9b;
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.auto-complete-open .material-label {
    border-bottom-color: #dadce0;
    z-index: 200;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.auto-complete-open .material-label.focus {
    border-bottom-color: #dadce0;
}

.checkbox,
.radio {
    text-align: left;
}

.checkbox label,
.radio label {
    padding: 10px 0;
    cursor: pointer;
}

.checkbox label.row,
.radio label.row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.checkbox label:hover .option-icon > div,
.radio label:hover .option-icon > div {
    background-color: rgba(0, 0, 0, 0.05);
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    animation: animate-in 0.1s ease;
    -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
}

.checkbox input,
.radio input {
    display: none;
}

.checkbox input[type='text'],
.radio input[type='text'] {
    display: block;
}

.checkbox .option-icon,
.radio .option-icon {
    position: relative;
    margin-right: 1em;
    background-color: #fff;
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 2px;
    border: 2px solid #bdbdbd;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox .checked .option-icon,
.radio .checked .option-icon {
    background-color: #2862c2;
    border-color: #2862c2;
    -webkit-transition: 0.13s ease 0s;
    -moz-transition: 0.13s ease 0s;
    -ms-transition: 0.13s ease 0s;
    -o-transition: 0.13s ease 0s;
    transition: 0.13s ease 0s;
}

.checkbox.invert .option-icon,
.radio.invert .option-icon {
    background-color: #fff;
}

.checkbox.invert .checked .option-icon:after,
.radio.invert .checked .option-icon:after {
    border-color: #86c25e;
}

.checkbox .checked .option-icon:after {
    content: '';
    display: block;
    width: 7px;
    height: 13px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    margin-bottom: 3px;
    transform: rotate(45deg);
}

.radio .checked .option-icon {
    background-color: transparent;
    border-width: 2px;
}

.radio .checked .option-icon:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #2862c2;
    border-radius: 50%;
}

.radio .option-icon {
    border-radius: 50%;
}

.radio .option-icon > div {
    border-radius: 50%;
}

.radio.inline > label {
    margin-right: 10px;
}

.app-select {
    text-align: left;
}

.app-select.valid .toggle {
    border-bottom-width: 2px;
    border-bottom-color: #2862c2;
}

.app-select .toggle {
    padding: 9px 0px;
    background-color: #fff;
    border-bottom: 1px solid #bdbdbd;
}

.app-select .toggle .arrow {
    border-color: inherit;
    display: inherit;
    margin: auto 0 auto auto;
}

.app-select .no-gutters .col-7 {
    padding-left: 10px;
}

.app-select .prefix {
    font-weight: 400;
    transform-origin: bottom left;
    -webkit-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    transition: 0.14s cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    color: rgba(0, 0, 0, 0.53);
    font-size: 16px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    max-width: 100%;
}

.app-select .body {
    background-color: #fff;
}

.app-select .body .option-icon {
    margin-left: 20px;
}

.app-select .radio label .col-2 {
    margin-right: 5px;
}

.options .option-field {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    width: calc(100% - 30px);
}

button {
    outline: none;
    -webkit-transition: 0.11s ease;
    -moz-transition: 0.11s ease;
    -ms-transition: 0.11s ease;
    -o-transition: 0.11s ease;
    transition: 0.11s ease;
    background-color: transparent;
    border: none;
    font-family: inherit;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
}

button::-moz-focus-inner {
    border: 0;
}

button:disabled {
    cursor: not-allowed;
}

button.plain {
    font-weight: 500;
    padding: 0;
    color: #5f6368;
}

button.plain:hover {
    color: #000;
}

a:hover,
a:active {
    cursor: pointer;
}

.btn {
    display: inline-block;
    font-size: 14px;
    -webkit-tap-highlight-color: #bdbdbd;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    border-radius: 25px;
    background-color: #4086fa;
    padding: 7px 11px;
}

.btn.black {
    background-color: #000;
}

.btn.off {
    background-color: #e2e2e2;
    cursor: not-allowed;
}

.btn.off:hover {
    background-color: #e2e2e2;
}

.btn.lg {
    font-size: 1.5em;
}

.btn.cta {
    height: 40px;
    background-color: #1a73e8;
    min-width: 100px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
}

.btn.cta:hover,
.btn.cta:focus {
    -webkit-box-shadow: 0 0px 3px rgba(32, 33, 36, 0.18), 0 1px 20px rgba(32, 33, 36, 0.15);
    -moz-box-shadow: 0 0px 3px rgba(32, 33, 36, 0.18), 0 1px 20px rgba(32, 33, 36, 0.15);
    box-shadow: 0 0px 3px rgba(32, 33, 36, 0.18), 0 1px 20px rgba(32, 33, 36, 0.15);
}

.btn.gog {
    background-color: #fff;
    color: #092a49;
    border-radius: 30px;
    padding: 8px 15px;
    text-transform: none;
    border: 2px solid;
}

.btn.gog:hover,
.btn.gog:focus {
    -webkit-box-shadow: 0 0px 3px rgba(32, 33, 36, 0.18), 0 1px 20px rgba(32, 33, 36, 0.15);
    -moz-box-shadow: 0 0px 3px rgba(32, 33, 36, 0.18), 0 1px 20px rgba(32, 33, 36, 0.15);
    box-shadow: 0 0px 3px rgba(32, 33, 36, 0.18), 0 1px 20px rgba(32, 33, 36, 0.15);
}

.btn.sub {
    background-color: transparent;
    color: #0cd605;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.btn.sub.cta {
    color: #0cd605;
}

.btn.sub.cta:hover {
    color: #fff;
}

.btn.sub.back:before {
    font: 1em 'Material Symbols Outlined' !important;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    content: 'arrow_back_ios';
    vertical-align: baseline;
}

.btn.sub.left {
    padding-left: 0;
    text-align: left;
}

.btn.add:before {
    font: 1em 'Material Symbols Outlined' !important;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    vertical-align: sub;
    content: 'note_add';
    margin-right: 0.5em;
}

.btn.next:after {
    font: 1em 'Material Symbols Outlined' !important;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    vertical-align: sub;
    content: 'arrow_forward_ios';
    margin-left: 1em;
}

.btn.min {
    background-color: transparent;
    padding: 5px 10px;
    color: inherit;
    border-radius: 4px;
    border: 1px solid #dadce0;
}

.btn.opaq {
    background-color: transparent;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.14);
    height: 36px;
    line-height: 36px;
    padding: 0 0 !important;
    width: 100%;
    border-radius: 10px;
    color: #5f6368;
}

.btn.opaq i {
    color: inherit;
}

.btn.opaq:hover {
    color: #000;
    background-color: rgba(0, 0, 0, 0.01);
}

.btn.lower {
    text-transform: none;
}

.btn:disabled {
    background-color: #ddd;
}

.link {
    text-transform: uppercase;
    font-weight: 500;
    color: #4086fa;
    cursor: pointer;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0;
}

.link > * {
    display: inline-block;
    vertical-align: middle;
}

.link i {
    font-size: 16px;
    padding-left: 8px;
}

a.plain {
    cursor: alias;
}

a.plain:hover {
    color: #1a73e8;
    text-decoration: underline;
}

.area.buttons {
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}

.area.buttons > *:first-child {
    margin-right: 5px;
}

button.delete {
    padding: 11px 19px;
    border-radius: 6px;
    display: inline-block;
    font-size: 14px;
    border: 2px solid #ff5252;
    color: #ff5252;
}

button.border.white {
    padding: 11px 19px;
    border-radius: 6px;
    display: inline-block;
    font-size: 14px;
    border: 2px solid #fff;
    color: #fff;
}

.expandable-call-button {
    position: relative;
}

.expandable-call-button .phone-number {
    display: none;
    opacity: 1;
    background-color: #fff;
    color: #0cd605;
    padding: 7px 7px 2px;
    top: -50px;
    font-weight: 500;
    min-width: 9em;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28), 0 1px 25px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28), 0 1px 25px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28), 0 1px 25px rgba(0, 0, 0, 0.08);
    letter-spacing: 0.2px;
    left: 0;
    right: 0;
    border-radius: 3px 3px 12px;
    -webkit-transition: 0.16s ease;
    -moz-transition: 0.16s ease;
    -ms-transition: 0.16s ease;
    -o-transition: 0.16s ease;
    transition: 0.16s ease;
}

.expandable-call-button .phone-number a {
    padding: 0 0 !important;
}

.expandable-call-button .phone-number .triangle {
    position: absolute;
    left: 10px;
    color: #fff;
    text-shadow: 0 2px rgba(0, 0, 0, 0.18);
    bottom: -14px;
    transform: scale(1.75);
    transform-origin: center top;
}

.expandable-call-button .phone-number.open {
    position: absolute;
    z-index: 999;
    display: block;
}

.expandable-call-button .phone-number.open.transitioning {
    opacity: 1;
}

.login-page {
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: 'stretch';
    justify-content: 'stretch';
}

.login-page-wrapper {
    padding-top: 6vh;
}

.login-page-inner {
    margin: 0 5px 30px;
    padding: 30px 20px;
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.desktop .login-page {
    height: 100vh;
}

.loading-spinner-full {
    position: fixed;
    height: 100vh;
    text-align: center;
    width: 100vw;
    top: 0;
    left: 0;
    margin-top: 40vh;
}

.loading {
    border-radius: 50%;
    width: 5em;
    margin: auto;
    left: 0;
    right: 0;
    height: 5em;
    font-size: 10px;
    position: fixed;
    z-index: 209;
    -webkit-box-shadow: 0px 0px 100px 40px rgba(255, 255, 255, 0.6),
    inset 0px 0px 10px 20px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 0px 0px 100px 40px rgba(255, 255, 255, 0.6),
    inset 0px 0px 10px 20px rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 100px 40px rgba(255, 255, 255, 0.6),
    inset 0px 0px 10px 20px rgba(255, 255, 255, 0.3);
    top: 30%;
    text-indent: -9999em;
    border-top: 0 solid #d380b6;
    border-right: 0 solid #69c8c4;
    border-bottom: 0 solid #86c25e;
    border-left: 0 solid #ff8c28;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.loading:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
}

.loading.inside {
    position: absolute;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.toggle-container .body {
    -webkit-transition: height 0.1s ease;
    -moz-transition: height 0.1s ease;
    -ms-transition: height 0.1s ease;
    -o-transition: height 0.1s ease;
    transition: height 0.1s ease;
    border-bottom: 1px solid #dadce0;
}

.toggle-container:last-of-type .body {
    border-bottom: 0;
}

.toggle {
    cursor: pointer;
    background-color: inherit;
    z-index: 1;
    position: relative;
}

.toggle:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.toggle .title {
    position: relative;
    padding: 15px 20px;
}

.toggle .title .toggle-icon {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: 0.16s ease;
    -moz-transition: 0.16s ease;
    -ms-transition: 0.16s ease;
    -o-transition: 0.16s ease;
    transition: 0.16s ease;
}

.toggle .title .toggle-icon i {
    color: #646a6e;
}

.toggle.open .title .toggle-icon {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

object.white path {
    fill: #fff;
}

.svg-icon {
    position: relative;
}

.svg-icon > div {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.svg-icon.center {
    vertical-align: middle;
    display: inline-block;
}

.c-white i.material-icons {
    color: #fff;
}

.material-icons {
    vertical-align: middle;
    color: #646a6e;
    font-size: 21px;
}

.material-icons.xs {
    font-size: 14px;
}

.material-icons.sm {
    font-size: 19px;
}

.material-icons.lg {
    font-size: 31px;
}

.material-icons.xl {
    font-size: 42px;
}

.justify i {
    width: 1em;
}

i.bg-red {
    background-color: #ff5252;
    color: #fff;
}

i.bg-orange {
    background-color: #ff8c28;
    color: #fff;
}

i.bg-green {
    background-color: #86c25e;
    color: #fff;
}

i.bg-grey {
    background-color: #e6e6e6;
    color: #fff;
}

i.bg-yellow {
    background-color: #fc0;
    color: #fff;
}

.tab {
    background-color: #fff;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: auto;
}

.tab.left {
    -webkit-justify-content: start;
    justify-content: start;
}

.tab input {
    display: none;
}

.tab > li {
    position: relative;
    text-align: center;
    cursor: pointer;
}

.tab > li span {
    display: block;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 10px;
    color: #6c7e92;
}

.tab > li .line {
    height: 3px;
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.tab > li.active .line {
    background-color: #86c25e;
}

.tab > li .counter {
    position: absolute;
    top: 1px;
    color: #fff;
    right: -6px;
    border-radius: 50%;
    padding: 3px;
    background-color: #86c25e;
    font-size: 11px;
    font-weight: 500;
    height: 20px;
    width: 20px;
}

.select-input {
    position: relative;
    z-index: 100;
    cursor: pointer;
}

.select-input .material-input {
    z-index: 50;
}

.select-input.native .arrow {
    width: 10px;
    position: absolute;
    right: 20px;
    top: 16px;
    z-index: 200;
}

.select-input input {
    width: 0;
    overflow: hidden;
    opacity: 0;
}

.select-input .arrow {
    border-color: #b4b4b4;
    padding: 4px;
    margin-left: auto;
    margin-right: 0;
}

.select-input .select-icon {
    padding-right: 5px;
    color: #b4b4b4;
    margin-left: -5px;
    margin-left: 0;
}

.select-input .trigger-body .values {
    margin: 0 10px;
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.select-input .trigger-body .values.single > span i {
    display: none;
}

.select-input .trigger-body .values.multiple > span {
    display: inline-block;
    padding: 3px 5px 3px 0;
    text-transform: capitalize;
}

.select-input .trigger-body .values.multiple > span i {
    vertical-align: middle;
    display: none;
}

.select-input.maximized .body {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    background-color: transparent;
}

.select-input.maximized .body .label,
.select-input.maximized .body label {
    padding: 10px 0;
}

.select-input.maximized .arrow {
    display: none;
}

.select-input.maximized .trigger-body .values {
    margin: 0 10px;
    padding-right: 10px;
    overflow: visible;
    white-space: pre-wrap;
}

.select-input.focus:not(.open) .material-input .material-label {
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.select-input.valid .prefix {
    padding-right: 20px;
    border-right: 1px solid #dadce0;
    font-weight: bold;
}

.select-input .material-label {
    overflow: hidden;
    border-radius: 5px;
    height: 36px;
    padding: 5px 12px;
    border: 1px solid #dadce0;
}

.select-input .material-label .prefix {
    min-width: 10em;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
}

.select-input .material-label:hover {
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.select-input.open {
    z-index: 101;
}

.select-input.open:hover {
    z-index: 103;
}

.select-input.open .material-label {
    border: 1px solid #ccc;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom-color: transparent;
    background-color: #fff;
    z-index: 3;
    border-radius: 9px;
}

.select-input.open .prefix {
    font-weight: bold;
}

.select-input.in-trans .body {
    animation: 0.13s ease-out user-menu-button-close;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.select-input.in-trans .body .inner {
    opacity: 0;
}

.select-input.static-body .body {
    position: static;
    max-height: initial;
}

.select-input .body {
    position: absolute;
    max-height: 300px;
    overflow-y: auto;
    padding: 5px 0;
    background-color: #fff;
    z-index: 1;
    left: 0;
    width: 100%;
    transform-origin: center top;
    border-top: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    border: none;
}

.select-input .body label {
    border-bottom: 0;
    padding: 10px 15px;
}

.select-input .body label.hover {
    background-color: #f0f1f2;
}

.avatar {
    height: 35px;
    width: 35px;
    background-color: #f1f2f3;
    overflow: hidden;
    position: relative;
    color: #202124;
    text-align: center;
    border-radius: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar.wide img {
    max-width: 200%;
}

.avatar.lg {
    height: 75px;
    width: 75px;
}

.avatar.lg img {
    max-width: 110%;
    width: 100%;
}

.avatar.md {
    height: 46px;
    width: 46px;
}

.avatar.md img {
    max-width: 46px;
}

.avatar.xl {
    height: 120px;
    width: 120px;
}

.avatar.xl img {
    max-width: 120px;
}

.avatar + .cnt {
    position: absolute;
    border-radius: 50%;
    right: 8px;
    font-size: 13px;
    min-width: 18px;
    background-color: #ee386d;
    height: 18px;
    top: -6px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 1.3;
    padding: 0;
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.avatar img {
    max-width: 110%;
    height: 100%;
    border-radius: 7px;
}

.avatar:hover .children {
    background-color: #86c25e;
}

.avatar .children {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.68);
    color: #fff;
    padding: 5px 5px 10px;
    width: 100%;
    font-size: 13px;
    cursor: pointer;
}

.ReactCrop__crop-selection {
    border-radius: 50%;
}

.initials-bubble {
    display: block;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
}

.circle-fill {
    position: relative;
    width: 100%;
    height: 100%;
}

.circle-fill svg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.circle-fill svg.circleFill {
    z-index: 1;
    stroke-dasharray: 322;
    stroke-dashoffset: 322;
    transition: all 3s;
    transform: rotate(-90deg);
}

.circle-fill svg.circleFill.filled {
    z-index: 0;
}

.ReactCrop__image {
    max-height: 45vh;
}

.dropzone-image i {
    display: none;
}

.dropzone-image:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.dropzone-image:hover i {
    display: flex;
    margin: auto;
    color: #fff;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
}

.row-with-icon.reverse > *:first-child {
    margin-left: 1em;
    margin-right: 0;
}

.row-with-icon.reverse.sm > *:first-child {
    margin-left: 5px;
    margin-right: 0;
}

.row-with-icon > *:first-child {
    margin-right: 1em;
}

.row-with-icon.sm > *:first-child {
    margin-right: 5px;
}

.row-with-icon.lg > *:first-child {
    margin-right: 20px;
}

.row-with-icon.middle {
    -webkit-align-items: center;
    align-items: center;
}

.jump-list {
    width: 40px;
    position: relative;
}

.jump-list > button {
    position: relative;
    z-index: 2;
}

.jump-list > button:hover i {
    color: #202124;
}

.jump-list > button i {
    font-size: 30px;
}

.jump-list.open {
    z-index: 2;
}

.jump-list.close.in-trans .body {
    animation: 0.14s ease-out user-menu-button-close;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.jump-list > .body {
    position: absolute;
    border-radius: 2px;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.18), 0 2px 50px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.18), 0 2px 50px rgba(0, 0, 0, 0.18);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.18), 0 2px 50px rgba(0, 0, 0, 0.18);
    padding: 10px 0;
    border: 1px solid #dadce0;
    right: 30px;
    background-color: #fff;
    top: 0;
    z-index: 102;
    min-width: 210px;
    max-width: 265px;
    transform-origin: 90% 10%;
    animation: 0.14s ease-in user-menu-button;
}

.jump-list > .body > * {
    display: block;
    border-bottom: 1px solid #dadce0;
    padding: 10px;
    width: 100%;
    text-align: left;
    cursor: default;
}

.jump-list > .body > *:hover:not(.custom) {
    background-color: rgba(0, 0, 0, 0.07);
}

.jump-list > .body > *:last-child {
    border-bottom: 0;
}

.jump-list > .body > *.top-bar {
    padding: 0;
    margin-top: -10px;
}

.jump-list > .body > *.np {
    padding: 0;
}

.jump-list.no-hover > .body > *:hover {
    background-color: transparent;
}

.check-button {
    -webkit-transition: background-color 0.2s ease;
    -moz-transition: background-color 0.2s ease;
    -ms-transition: background-color 0.2s ease;
    -o-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    padding: 5px;
    cursor: pointer;
    border-radius: 2px;
    background-color: #f0f1f2;
    font-size: 13px;
    color: #898e91;
    text-shadow: 1px 1px rgba(255, 255, 255, 0.65);
}

.check-button.on {
    background-color: #2ece40;
    box-shadow: 0 0 15px -5px #2ece40;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.47);
    color: #fff;
}

.check-button.on.negative {
    background-color: #f0134f;
    box-shadow: 0 0 15px -5px #f0134f;
}

.check-button:hover {
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.check-button input {
    display: none;
}

.alert-wrapper {
    position: fixed;
    z-index: 335;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    overflow-y: auto;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.alert-wrapper.center .alert-inner {
    text-align: center !important;
}

.alert-wrapper .alert-inner {
    min-width: 297px;
    text-align: center;
    -webkit-box-shadow: 0 0 75px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 75px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 75px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    margin: auto 0;
    max-width: 675px;
    background-color: #fff;
    text-align: left;
    color: #000;
    border-radius: 4px;
    min-width: 570px;
    overflow-x: hidden;
}

.alert-wrapper .alert-inner .alert-body {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 35px;
}

.alert-wrapper .alert-inner .alert-full-row {
    margin-left: -15px;
    margin-right: -15px;
}

.alert-wrapper .alert-inner button {
    margin-right: 5px;
}

.alert-wrapper .alert-inner h3.title small {
    font-weight: normal;
}

.alert-wrapper .alert-inner .close {
    cursor: pointer;
    z-index: 2;
    padding: 16px 22px;
}

.alert-wrapper .alert-inner .close i {
    font-size: 18px !important;
}

.alert-wrapper .alert-inner .close:hover i {
    color: #000;
}

.alert-wrapper .alert-inner .buttons {
    padding-bottom: 0;
}

.alert-wrapper .alert-inner .buttons:empty {
    display: none;
}

.alert-wrapper .alert-inner .children {
    text-align: left;
}

.alert-wrapper .alert-inner .children h3 {
    text-align: center;
}

.alert-wrapper.full-screen .alert-inner {
    max-width: 100%;
    min-height: 100%;
    width: 100%;
    margin: 0;
}

.alert-wrapper.open {
    height: 100%;
}

.alert-wrapper.open .alert-inner {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.alert-wrapper .alert-header {
    position: absolute;
    width: calc(100% - 60px);
    height: 50px;
    top: 0;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.alert-wrapper .alert-header + div {
    margin-top: 20px;
}

div.overlay {
    position: fixed;
    z-index: 330;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    top: 0;
    left: 0;
}

div.overlay .fade-in {
    background-color: #12181e;
    width: 100%;
    height: 100%;
}

.alert-root + .alert-root .alert-wrapper,
.alert-root .alert-root .alert-wrapper {
    z-index: 336;
}

.alert-root + .alert-root .overlay,
.alert-root .alert-root .overlay {
    z-index: 335;
    opacity: 0.5;
    background-color: #12181e;
}

.alert-root .alert-wrapper.sub .alert-inner .alert-body {
    padding-left: 27px;
    padding-right: 27px;
}

.alert-root .alert-wrapper.sub .alert-inner .alert-full-row {
    margin-left: -27px;
    margin-right: -27px;
}

.popup-mobile {
    height: 100%;
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
}

.popup-mobile .alert-wrapper {
    position: absolute;
}

.popup-mobile .footer {
    visibility: hidden;
    z-index: -1;
}

.popup-mobile .footer .toggle i {
    display: none;
}

.alert-wrapper .alert-inner h3.title {
    margin: 10px 0 20px;
    font-size: 20px;
}

.alert-wrapper .alert-inner .popup-footer {
    text-align: center;
    border-top: 1px solid #e6e6e6;
    margin-bottom: -35px;
    margin-left: -55px;
    margin-right: -55px;
    background-color: #fff;
    padding: 25px;
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem;
}

.alert-wrapper.calendar-item .alert-inner .close i {
    color: #000;
}

.alert-wrapper.calendar-item .alert-inner .close:hover i {
    color: #50575b;
}

.banner {
    position: relative;
}

.banner.size-md {
    height: 400px;
}

.banner.size-sm {
    height: 250px;
}

.banner.size-lg {
    height: 100vh;
}

.banner .background {
    height: 100%;
    width: 100%;
    background-color: #16293e;
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.banner .background .inside {
    position: relative;
    width: 100%;
    z-index: 2;
}

.banner .background picture {
    height: 100%;
    width: 100%;
    text-align: center;
    position: absolute;
    overflow: hidden;
    top: 0;
}

.banner .background img,
.banner .background .bg-image {
    min-height: 100%;
    min-width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    max-width: 140%;
    top: 0;
}

.banner .background.auto-height {
    position: relative;
}

.popup-mobile .wizard .wizard-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 500;
    border-top: 1px solid #dadce0;
}

.popup-mobile .wizard .step .error {
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    font-weight: bold;
}

.wizard-open body.ftr #root {
    margin-bottom: 0;
    min-height: 0;
}

.wizard-open body.ftr #root footer.footer {
    position: static !important;
}

.wizard {
    max-width: 100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.wizard .wizard-footer-disclaimer i {
    color: #bdbdbd;
}

.wizard .wrapper {
    overflow: hidden;
    margin: 10px auto 15px;
    position: relative;
    z-index: 1;
}

.wizard .wizard-footer {
    background-color: #fff;
}

.wizard ul.wizard-list {
    -webkit-transition: transform 0.22s ease;
    -moz-transition: transform 0.22s ease;
    -ms-transition: transform 0.22s ease;
    -o-transition: transform 0.22s ease;
    transition: transform 0.22s ease;
    white-space: nowrap;
}

.wizard ul.wizard-list > li {
    display: inline-block;
    vertical-align: top;
    min-height: 225px;
    overflow-x: hidden;
    padding: 3px;
    white-space: normal;
}

.wizard ul.wizard-list > li.tail .step,
.wizard ul.wizard-list > li.head .step {
    max-height: 10px;
}

.wizard ul.wizard-list > li .step {
    width: auto;
    white-space: normal;
    padding-top: 10px;
    max-height: 40px;
    overflow: hidden;
}

.wizard ul.wizard-list > li.current .step {
    max-height: calc(100vh - 230px);
    overflow-y: auto;
    will-change: transform;
}

.wizard ul.wizard-list > li.current .step.wizard-user-form-var1,
.wizard ul.wizard-list > li.current .step.wizard-waiting {
    max-height: 100%;
}

.wizard ul.wizard-list > li h3 {
    font-size: 20px;
    white-space: normal;
    color: #000;
}

.wizard ul.wizard-list > li h3 small {
    font-weight: normal;
    font-size: 14px;
    display: block;
    margin-top: 5px;
}

.wizard .input-date .DayPickerInput-OverlayWrapper {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.wizard .question-options.button-s .options label {
    -webkit-transition: 0.11s ease;
    -moz-transition: 0.11s ease;
    -ms-transition: 0.11s ease;
    -o-transition: 0.11s ease;
    transition: 0.11s ease;
    padding: 12px;
    background-color: #f0f1f2;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
}

.wizard .question-options.button-s .options label.checked {
    background-color: #2862c2;
    color: #fff;
}

.wizard .question-options.button-s .options label.checked .option-icon {
    border-color: #fff;
}

.wizard .question-options.button-s .options label.checked .option-icon:after {
    background-color: #fff;
}

.wizard .question-options.button-s .options .checkbox label.checked .option-icon {
    border-color: #fff;
}

.wizard .question-options.button-s .options .checkbox label.checked .option-icon:after {
    background-color: transparent;
    border-color: #fff;
}

.wizard .options .row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wizard .options label {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}

.wizard .options > *:last-of-type label {
    border-bottom: 0;
}

.wizard ul.files {
    text-align: left;
    margin-top: 15px;
}

.wizard ul.files > li {
    padding-left: 15px;
    border-bottom: 1px solid #dadce0;
    padding-top: 15px;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow-y: hidden;
    padding-bottom: 13px;
    background-color: #fff;
}

.wizard-control {
    max-width: 300px !important;
    padding: 10px 0;
    margin-bottom: 0;
}

.wizard-control button {
    padding: 13px 18px;
}

.wizard-control .btn:first-of-type {
    min-width: 130px;
    margin-right: 0;
    margin-left: auto;
}

.wizard-control .wizard-back i {
    padding-right: 10px;
    padding-left: 0;
}

.wizard-header {
    line-height: 2;
    position: relative;
}

.options-with-icon input {
    display: none;
}

.options-with-icon label {
    display: block;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    border: 2px solid #dadce0;
    padding: 0;
    margin-bottom: 10px;
    height: calc(100% - 10px);
    margin-bottom: 0;
}

.options-with-icon label .option-icon {
    display: none;
}

.options-with-icon label .option-value {
    font-weight: bold;
    padding: 5px 0;
}

.options-with-icon label.checked {
    border-color: #2862c2;
}

.options-with-icon label.checked .option-img {
    position: relative;
    z-index: 1;
}

.options-with-icon label.checked .option-img:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #2862c2;
    opacity: 0.5;
    z-index: -1;
}

.options-with-icon label.checked .option-img i {
    user-select: none;
    color: #fff;
    font-size: 40px;
}

.options-with-icon label.checked .option-img i::-moz-selection,
.options-with-icon label.checked .option-img i::selection {
    background-color: transparent;
}

.progress-bar-wrapper {
    background-color: #f5f7f6;
    width: 100%;
    border-radius: 1px;
    overflow: hidden;
    height: 5px;
}

.progress-bar {
    height: 5px;
    width: 0;
    background-color: #86c25e;
    -webkit-transition: width 0.2s ease-in;
    -moz-transition: width 0.2s ease-in;
    -ms-transition: width 0.2s ease-in;
    -o-transition: width 0.2s ease-in;
    transition: width 0.2s ease-in;
    border-radius: 4px;
}

html.wizard-open {
    height: 100%;
    overflow-scrolling: auto;
    -webkit-overflow-scrolling: auto;
    position: relative;
}

html.wizard-open body {
    height: 100%;
    overflow-scrolling: auto;
    -webkit-overflow-scrolling: auto;
    position: relative;
}

html.wizard-open .footer {
    visibility: hidden;
    z-index: -1;
}

html.wizard-open .footer .toggle i {
    display: none;
}

.wizard-finished-container {
    vertical-align: middle !important;
}

.wizard-waiting {
    font-size: 15px;
}

.wizard-matches-counter {
    font-size: 33px;
    font-weight: 500;
}

.wizard-matches-counter .counter {
    color: #86c25e;
}

.wizard-waiting-header {
    background-color: #f5f7f6;
    line-height: 18px;
    color: #202124;
}

.wizard-waiting-header strong {
    display: block;
    margin-bottom: -0.2em;
}

.wizard-waiting-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}

.wizard-waiting-footer .btn {
    color: #fff;
    padding: 12px 15px 9px 10px;
}

.wizard-waiting-footer .btn.sub {
    color: #86c25e;
}

.wizard-control .wizard-next,
.wizard-control .wizard.send {
    background-color: #68c33d;
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.wizard-control .wizard-next:hover,
.wizard-control .wizard.send:hover {
    background-color: #5eb933;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 25px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 25px rgba(0, 0, 0, 0.18);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0 25px rgba(0, 0, 0, 0.18);
}

html.wizard-mobile.wizard-open {
    width: calc(100% + 0px);
    position: fixed;
    top: 0;
    overflow: hidden;
}

html.wizard-mobile.wizard-open .alert-wrapper {
    position: absolute;
}

html.wizard-mobile.wizard-open .wizard ul.wizard-list > li {
    min-height: 195px;
}

.close-wizard-with-send-option .alert-inner {
    background-color: #f5f7f6;
}

.close-wizard-with-send-option .alert-inner .bg-white {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.close-wizard-with-send-option .alert-inner .request-quote-row label span {
    color: grey;
}

.wizard-welcome,
.quote-location {
    position: relative;
}

.quote-location .request-quote-row {
    max-width: 100%;
}

.wizard-user-form h3,
.wizard-user-form-var1 h3 {
    text-align: left;
}

.with-balloon {
    position: relative;
    display: inline-block;
}

.with-balloon .balloon {
    position: absolute;
    margin: 0 auto;
    padding: 3px 5px;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    z-index: 30;
    right: -10px;
    bottom: 30px;
    min-width: 120px;
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.with-balloon .balloon:empty {
    display: none;
}

@-webkit-keyframes fade-from-bottom {
    0% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes fade-from-bottom {
    0% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-o-keyframes fade-from-bottom {
    0% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-from-bottom {
    0% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-to-bottom {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
}

@-moz-keyframes fade-to-bottom {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
}

@-o-keyframes fade-to-bottom {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
}

@keyframes fade-to-bottom {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes animate-in {
    0% {
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-moz-keyframes animate-in {
    0% {
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-o-keyframes animate-in {
    0% {
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes animate-in {
    0% {
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes animate-out {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
    }
}

@-moz-keyframes animate-out {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
    }
}

@-o-keyframes animate-out {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
    }
}

@keyframes animate-out {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
    }
}

.fade-in {
    -webkit-animation-name: fade-in;
    -moz-animation-name: fade-in;
    -o-animation-name: fade-in;
    animation-name: fade-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -o-animation-duration: 0.2s;
    animation-duration: 0.2s;
}

.fade-in.slow {
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    -o-animation-duration: 0.4s;
    animation-duration: 0.4s;
}

.fade-from-bottom {
    -webkit-animation-name: fade-from-bottom;
    -moz-animation-name: fade-from-bottom;
    -o-animation-name: fade-from-bottom;
    animation-name: fade-from-bottom;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.3s;
    -moz-animation-duration: 0.3s;
    -o-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

.fade-to-bottom {
    -webkit-animation-name: fade-to-bottom;
    -moz-animation-name: fade-to-bottom;
    -o-animation-name: fade-to-bottom;
    animation-name: fade-to-bottom;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.3s;
    -moz-animation-duration: 0.3s;
    -o-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

.animate-in {
    -webkit-animation-name: animate-in;
    -moz-animation-name: animate-in;
    -o-animation-name: animate-in;
    animation-name: animate-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.16s;
    -moz-animation-duration: 0.16s;
    -o-animation-duration: 0.16s;
    animation-duration: 0.16s;
}

.animate-out {
    -webkit-animation-name: animate-out;
    -moz-animation-name: animate-out;
    -o-animation-name: animate-out;
    animation-name: animate-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.16s;
    -moz-animation-duration: 0.16s;
    -o-animation-duration: 0.16s;
    animation-duration: 0.16s;
}

@keyframes user-menu-button {
    0% {
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        -o-transform: scale(0.1);
        transform: scale(0.1);
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes user-menu-button-close {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.0001);
        -moz-transform: scale(0.0001);
        -ms-transform: scale(0.0001);
        -o-transform: scale(0.0001);
        transform: scale(0.0001);
        opacity: 0;
    }
}

@keyframes user-menu-button-inner {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.DayPicker-wrapper {
    position: relative;
    width: 100%;
}

.linear-progress-material {
    background: #d2e2ff;
    height: 5px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    -webkit-animation: start 0s ease-in;
    animation: start 0s ease-in;
}

.linear-progress-material.fixed {
    position: fixed;
    top: 0;
    left: 0;
}

.linear-progress-material.small {
    width: 15%;
    height: 2px;
}

.linear-progress-material .bar {
    background: #4086fa;
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
}

.linear-progress-material .bar1 {
    -webkit-animation: growBar1 1.5s infinite, moveBar1 1.5s infinite;
    animation: growBar1 1.5s infinite, moveBar1 1.5s infinite;
}

.linear-progress-material .bar2 {
    -webkit-animation: growBar2 1.5s infinite, moveBar2 1.5s infinite;
    animation: growBar2 1.5s infinite, moveBar2 1.5s infinite;
}

@-webkit-keyframes growBar1 {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    36.6% {
        -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    69.15% {
        -webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        -webkit-transform: scaleX(0.83);
        transform: scaleX(0.83);
    }
    100% {
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
}

@keyframes growBar1 {
    0% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    36.6% {
        -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    69.15% {
        -webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        -webkit-transform: scaleX(0.83);
        transform: scaleX(0.83);
    }
    100% {
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
}

@-webkit-keyframes moveBar1 {
    0% {
        left: -105.16667%;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    20% {
        left: -105.16667%;
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    }
    69.15% {
        left: 21.5%;
        -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
        animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    }
    100% {
        left: 95.44444%;
    }
}

@keyframes moveBar1 {
    0% {
        left: -105.16667%;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    20% {
        left: -105.16667%;
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    }
    69.15% {
        left: 21.5%;
        -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
        animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    }
    100% {
        left: 95.44444%;
    }
}

@-webkit-keyframes growBar2 {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
        animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    19.15% {
        -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
        animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
        -webkit-transform: scaleX(0.57);
        transform: scaleX(0.57);
    }
    44.15% {
        -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
        animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
        -webkit-transform: scaleX(0.91);
        transform: scaleX(0.91);
    }
    100% {
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
}

@keyframes growBar2 {
    0% {
        -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
        animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
    19.15% {
        -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
        animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
        -webkit-transform: scaleX(0.57);
        transform: scaleX(0.57);
    }
    44.15% {
        -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
        animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
        -webkit-transform: scaleX(0.91);
        transform: scaleX(0.91);
    }
    100% {
        -webkit-transform: scaleX(0.1);
        transform: scaleX(0.1);
    }
}

@-webkit-keyframes moveBar2 {
    0% {
        left: -54.88889%;
        -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
        animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    }
    25% {
        left: -17.25%;
        -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
        animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
    }
    48.35% {
        left: 29.5%;
        -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
        animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    }
    100% {
        left: 117.38889%;
    }
}

@keyframes moveBar2 {
    0% {
        left: -54.88889%;
        -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
        animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    }
    25% {
        left: -17.25%;
        -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
        animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
    }
    48.35% {
        left: 29.5%;
        -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
        animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    }
    100% {
        left: 117.38889%;
    }
}

@-webkit-keyframes start {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 20px;
        opacity: 1;
    }
}

@keyframes start {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 20px;
        opacity: 1;
    }
}

@-webkit-keyframes end {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 2px;
        opacity: 1;
    }
}

@keyframes end {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 2px;
        opacity: 1;
    }
}

@font-face {
    font-family: 'icomoon';
    src: url('https://d28vfzniqbsrlc.cloudfront.net/font/categoryIcons/icomoon.eot?x66kx1');
    src: url('https://d28vfzniqbsrlc.cloudfront.net/font/categoryIcons/icomoon.eot?x66kx1#iefix') format('embedded-opentype'),
    url('https://d28vfzniqbsrlc.cloudfront.net/font/categoryIcons/icomoon.ttf?x66kx1') format('truetype'),
    url('https://d28vfzniqbsrlc.cloudfront.net/font/categoryIcons/icomoon.woff?x66kx1') format('woff'),
    url('https://d28vfzniqbsrlc.cloudfront.net/font/categoryIcons/icomoon.svg?x66kx1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    font-family: "'icomoon'!important";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
    content: '\e900';
    color: inherit;
}

.icon-plate:before {
    content: '\e901';
    color: inherit;
}

.icon-bulb:before {
    content: '\e902';
    color: inherit;
}

.icon-plant:before {
    content: '\e903';
    color: inherit;
}

.icon-case:before {
    content: '\e904';
    color: inherit;
}

.icon-search:before {
    content: '\e905';
    color: inherit;
}

.icon-pen:before {
    content: '\e906';
    color: inherit;
}

.review {
    line-height: 1.625;
    padding: 15px 0;
}

.review .body {
    margin-top: 10px;
    line-height: 19px;
    color: #000;
}

.review .first-col-avatar {
    max-width: 45px;
}

.review-stars i {
    color: rgba(246, 140, 30, 0.3);
    font-size: 16px;
    vertical-align: middle;
    display: inline-block;
    width: 1em;
}

.review-stars i.c-empty {
    color: rgba(246, 140, 30, 0.3);
}

.review-stars i.c-full {
    color: #ff8c28;
}

.review-stars .nr-reviews {
    white-space: nowrap;
}

.review-stars .nr-reviews.inline {
    display: inline;
    color: #646a6e;
    font-weight: normal;
    padding-left: 0.5em;
    vertical-align: middle;
}

.review-stars-input input {
    display: none;
}

.carousel-review-style .review {
    background-color: #fff;
    padding: 45px 10px 50px;
    position: relative;
}

.carousel-review-style .review .review-stars {
    margin: 0;
}

.carousel-review-style .review:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    left: 20%;
    height: 5px;
    bottom: auto;
    border-left: 20px solid #f5f7f6;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    width: 20px;
    top: 0;
}

.carousel-review-style .review.bordered {
    border: 1px solid #e6e6e6;
    border-bottom: 3px solid #e6e6e6;
}

.carousel-review-style .review.bordered:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    left: calc(20% + 2px);
    height: 5px;
    bottom: auto;
    border-left: 17px solid #fff;
    border-right: 17px solid transparent;
    border-bottom: 17px solid transparent;
    width: 20px;
    top: -1px;
}

.carousel-review-style .review .body {
    margin-left: -40px;
    padding: 0;
}

.carousel-review-style .review .row.name-stars {
    display: block !important;
}

.carousel-review-style .review .row.name-stars > div {
    max-width: 100%;
}

.home-review {
    padding: 40px 20px 15px;
    min-height: 240px;
    border-radius: 7px;
    margin-bottom: 1px;
}

.home-review .review-stars i {
    font-size: 20px;
}

.home-review .avatar {
    border-radius: 50%;
}

.home-review .body {
    min-height: 11em;
}

.home-review .max-text-expand {
    font-size: 15px;
    line-height: 1.5em;
}

.home-review .review-name {
    font-size: 17px;
    color: #092a49;
}

.home-review .line {
    border: 2px solid;
    opacity: 0.9;
    max-width: 70px;
    margin: 6px 0;
}

.review-stats > .col-6:first-of-type {
    border-right: 1px solid #dadce0;
}

.review-stats .bar {
    border-radius: 10px;
    margin: 0 10px;
    height: 7px;
}

.review-stats .bar > div {
    height: 100%;
    border-radius: 10px;
}

.review-stats .review-stars i {
    font-size: 25px;
}

.bg-greenBright {
    background-color: #68c33d;
}

.bg-purple {
    background-color: #d8d6ff;
}

.bg-cyan {
    background-color: #69c8c4;
}

.bg-yellow {
    background-color: #fe0;
}

.bg-pink {
    background-color: #f7e2fd;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3 {
    font-weight: 700;
    color: #000;
    margin: 0;
}

h1.border,
h2.border,
h3.border,
h4.border,
h5.border,
.h1.border,
.h2.border,
.h3.border {
    border-bottom: 1px solid #dadce0;
    padding-bottom: 10px;
    font-weight: 700;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
.h1.light,
.h2.light,
.h3.light {
    font-weight: normal;
}

h1.cap,
h2.cap,
h3.cap,
h4.cap,
h5.cap,
.h1.cap,
.h2.cap,
.h3.cap {
    text-transform: uppercase;
    font-weight: 500;
}

h1 hr,
h2 hr,
h3 hr,
h4 hr,
h5 hr,
.h1 hr,
.h2 hr,
.h3 hr {
    margin: 0 0 15px;
}

span.score {
    font: 700 150% monospace;
    vertical-align: middle;
    color: #5f6368;
    background: rgba(243, 244, 246, 0.5);
    border-radius: 7px;
    padding: 0.1em 0.15em;
    text-shadow: 1px 1px 1px #fff;
}

.cap {
    text-transform: capitalize;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h1,
.h1 {
    font-size: 31px;
}

h2,
.h2 {
    font-size: 24px;
}

h3,
.h3 {
    font-size: 19px;
}

.h-plus {
    font-size: 1.75rem;
}

a {
    font-weight: normal;
    color: inherit;
    padding: 0;
    text-decoration: none;
}

strong {
    font-size: 16px;
    color: #161e37;
    font-weight: 600;
}

p {
    line-height: 1.625;
    line-height: 1.5;
}

abbr {
    text-decoration: none;
}

hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 1em 0 0;
    padding: 1em 0 0;
}

.clickable {
    cursor: pointer;
}

small {
    font-size: 85%;
    line-height: 1.2;
    display: inline-block;
}

.label {
    font-size: 0.75rem;
    display: inline-block;
    line-height: 1;
    padding: 4px 5px;
    border-radius: 15px;
    margin: 0;
}

small.text {
    color: #5f6368;
    font-size: 14px;
}

.light-text {
    font-weight: 300;
    color: #757575;
}

.fsize.sm {
    font-size: 12px;
}

.fsize.lg {
    font-size: 16px;
}

.fsize.xl {
    font-size: 22px;
}

.fsize.max {
    font-size: 1.75rem;
}

@media (min-width: 576px) {
    .fsize.max {
        font-size: 2.75rem;
    }
}

.fsize.md {
    font-size: 15px;
}

@media (max-width: 576px) {
    .fsize.m-sm {
        font-size: 12px !important;
    }
}

@media (max-width: 576px) {
    .fsize.m-md,
    .fsize.m-md span {
        font-size: 14px !important;
    }
}

span.lg {
    font-size: 125%;
}

.price {
    font-size: 1.4em;
    font-weight: 500;
}

.soft {
    color: #5f6368;
    font-size: 13px;
}

.ellipsis {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
}

.c-blue {
    color: #4086fa;
}

.c-purple {
    color: #d3dbdf;
}

.c-magenta {
    color: #ee386d;
}

.c-pink {
    color: #ff3173;
}

.c-orange {
    color: #ff8c28;
}

.c-green {
    color: #0cd605;
}

.c-yellow {
    color: #fe0;
}

.c-black {
    color: #000;
}

.c-cyan {
    color: #69c8c4;
}

.c-grey {
    color: #5f6368;
}

.c-purp-grey {
    color: #50575b;
}

.c-lighter {
    color: #9598aa;
}

.c-white {
    color: #fff;
}

i.round {
    padding: 3px;
    border-radius: 50%;
    text-align: center;
    font-size: 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    color: #fff;
}

.arrow {
    border: solid #000;
    border-border-top-width: medium;
    border-border-right-width: medium;
    border-border-bottom-width: medium;
    border-border-left-width: medium;
    border-width: 0 2px 2px 0;
    padding: 5px;
    vertical-align: middle;
    display: block;
    -webkit-transition: 0.16s ease;
    -moz-transition: 0.16s ease;
    -ms-transition: 0.16s ease;
    -o-transition: 0.16s ease;
    transition: 0.16s ease;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.arrow.down {
    -webkit-transform: translate(0, 3px) rotate(-133deg);
    -moz-transform: translate(0, 3px) rotate(-133deg);
    -ms-transform: translate(0, 3px) rotate(-133deg);
    -o-transform: translate(0, 3px) rotate(-133deg);
    transform: translate(0, 3px) rotate(-133deg);
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid #fff;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid #fff;
}

.triangle-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 13px solid #fff;
}

.triangle-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 13px solid #fff;
}

.static-fts > div:not(:last-of-type) {
    margin-bottom: 14px;
}

.static-fts .header {
    color: #757575;
    text-align: left;
}

.static-fts .row-with-icon {
    font-size: 15px;
}

.static-fts .row-with-icon i {
    color: #393939;
}

.error {
    color: #ee386d;
}

ul {
    list-style: none;
}

textarea:focus,
input:focus,
a:focus,
button:focus,
[tabindex] {
    outline: 0;
}

*:focus {
    outline: 0;
}

.upper {
    text-transform: uppercase;
    font-size: 90%;
}

body {
    color: rgba(0, 0, 0, 0.87);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 14px 'Work Sans', helvetiva, Arial, sans-serif;
    letter-spacing: 0.2px;
    margin: 0;
    border-top: 0;
}

body h1,
body h2,
body h3,
body h4,
body h5 {
    color: #000;
    font-family: "'Work Sans'", helvetiva, Arial, sans-serif;
}

body h1.light,
body h2.light,
body h3.light,
body h4.light,
body h5.light {
    font-weight: 500;
}

body small.card-h {
    font-size: 16px;
}

body a {
    font-family: "'Work Sans'", helvetiva, Arial, sans-serif;
}

body a.block {
    display: block;
}

.toggle-container .toggle-min .title {
    padding: 5px;
}

.toggle-container .toggle-min .title .toggle-icon i {
    font-size: 18px;
}

.title-sm {
    color: #9598aa;
}

.toggle.small .title {
    padding: 0 20px;
}

.high-light {
    position: absolute;
    left: 0;
    height: 100%;
    margin: auto;
    background: rgba(255, 255, 255, 0.85);
    padding: 5px;
    z-index: 2;
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

.busy {
    opacity: 0.33;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -ms-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
}

a i:hover,
button:hover i,
.clickable:hover i {
    color: #50575b;
}

.col-h {
    font-weight: 500;
}

.btn:hover {
    background-color: #1a73e8;
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.btn.warning {
    background-color: #ff3173;
}

.btn.ok {
    background-color: #0cd605;
}

.btn.is-loading {
    background-color: #5eb933;
    opacity: 0.6;
}

.back-button:hover i {
    color: #000;
}

.flex-table {
    border-spacing: 0;
    width: 100%;
}

.flex-table th {
    text-align: left;
}

.flex-table .table-col-expand {
    padding: 0 0 !important;
}

.flex-table .table-col-expand.expanded {
    background-color: rgba(243, 244, 246, 0.5);
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #dadce0;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top: 0;
}

.flex-table .expand-button {
    width: 50px;
    border-bottom-color: transparent !important;
}

.flex-table .expand-button i {
    color: #9598aa;
}

.flex-table .expand-button:hover i {
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
}

.flex-table .row-loading td {
    padding: 0 0 !important;
    position: relative;
    margin-bottom: -2px;
}

.flex-table .row-loading td .linear-progress {
    top: -2px;
    margin-bottom: -2px;
}

@media screen and (max-width: 800px) {
    .flex-table tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0.5em 0;
        border: 1px solid #dadce0;
        border-radius: 7px;
    }

    .flex-table td,
    .flex-table th {
        flex: 1 1 150px;
    }
}

.list td .checkbox i,
.list th .checkbox i {
    color: #c4c4c4;
    font-size: 21px;
}

.list.compact td,
.list.compact th {
    padding: 5px;
}

.list.compact tr.list-item:not(:hover) {
    box-shadow: none;
}

.list .list-item {
    position: relative;
}

.list .list-item:not(:last-child) {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.list .list-item:hover {
    -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    -moz-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
    z-index: 200;
}

.list .list-item:hover i.material-icons {
    color: #000;
}

.list .list-item:hover.expand-on-click .expand-button i {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
}

.list .list-item.expanded {
    background-color: rgba(243, 244, 246, 0.5);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.list .list-item.expanded td {
    border-top: 1px solid #dadce0;
    border-bottom: 0;
}

.list .list-item.expanded td:first-of-type {
    border-left: 1px solid #dadce0;
}

.list .list-item.expanded td:last-of-type {
    border-right: 1px solid #dadce0;
}

.list .list-item.selected {
    background-color: rgba(64, 134, 250, 0.2);
}

.list .list-item.selected .checkbox i {
    color: #5f6368;
}

.list td,
.list th {
    padding: 8px 5px;
}

.list td:first-of-type,
.list th:first-of-type {
    padding-left: 10px;
}

.list td:last-of-type,
.list th:last-of-type {
    padding-right: 10px;
}

.list .th {
    box-shadow: 0 1px 0 #c4c4c4;
}

.list th {
    color: #5f6368;
    font-weight: 500;
    text-transform: capitalize;
}

.filters > div {
    padding-bottom: 10px;
}

.note {
    font-size: 12px;
    line-height: 1.2;
}

.note .note-body span {
    display: block;
}

.note .note-body span:last-of-type {
    padding-bottom: 5px;
}

.note .note-body span:first-of-type {
    line-height: 1.5;
}

.note.dissatisfied .col-12 span:first-of-type:before {
    font: 400 21px 'Material Symbols Outlined' !important;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    padding-right: 5px;
    vertical-align: middle;
    color: #ff3173;
    content: 'sentiment_very_dissatisfied';
}

.note.satisfied .col-12 span:first-of-type:before {
    font: 400 21px 'Material Symbols Outlined' !important;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    vertical-align: middle;
    padding-right: 5px;
    color: #0cd605;
    content: 'sentiment_satisfied_alt';
}

.office-card {
    border: 1px solid #dadce0;
    border-radius: 8px;
}

.office-nav {
    position: fixed;
    background-color: #fff;
    left: 0;
    max-width: inherit;
    height: 100%;
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
}

.office-nav i {
    color: #afb2b4;
}

.office-nav .active {
    color: #4086fa;
}

.office-nav .toggle-container .body {
    border-bottom: 0;
}

.office-nav .toggle.open {
    border-left: 2px solid #4086fa;
}

.office-nav .toggle.open i {
    color: #4086fa;
}

.office-nav a:hover {
    background-color: rgba(0, 0, 0, 0.06);
    display: block;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.select-input.select-min .material-label {
    padding: 5px 0;
}

.select-input.select-min label.checked {
    background-color: #f3f4f6;
}

.select-input.select-min .option-icon {
    display: none;
}

.select-input.select-min .arrow {
    display: none;
}

.select-input.select-min .label {
    margin-right: 5px;
}

.select-input.select-wrap-values .values {
    white-space: pre-wrap;
}

.select-input.select-wrap-values .values > * {
    margin-bottom: 5px;
}

.select-wide.open:not(.in-trans) .body {
    min-width: 200px;
}

.select-input.select-stealth .material-label {
    height: auto;
    border: 1px solid transparent;
    padding: 0;
    line-height: 2;
}

.select-input.select-stealth .material-label .arrow {
    display: initial;
    padding: 3px;
    margin-left: -10px;
    margin-right: 10px;
}

.select-input.select-stealth .values {
    margin: 0 0.5em 0 0;
}

.h1 .select-input label {
    font-size: 70%;
}

.with-hidden-row:hover .hidden-button-row,
.list-item:hover .hidden-button-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #fff;
}

.with-hidden-row .hidden-button-row,
.list-item .hidden-button-row {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

.time-picker .select-input .body {
    min-width: 0 !important;
    width: 150px;
}

.location-input .material-input {
    position: relative;
    z-index: 1;
}

.location-input .material-label.focus {
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    border: 1px solid #092a49;
}

.location-input.auto-complete-open {
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    border-radius: 20px 20px 0 0;
}

.location-input.auto-complete-open .material-label {
    border-radius: 20px 20px 0 0;
    border-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.location-input.auto-complete-open .material-label:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.location-input .autocomplete-container {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
}

.material-input .material-label .label-text.up {
    color: rgba(0, 0, 0, 0.87);
}

.material-input .material-label input {
    color: rgba(0, 0, 0, 0.87);
}

.new-g-style .material-label:hover {
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
}

.select-input .radio > label:hover {
    background-color: rgba(243, 244, 246, 0.5);
}

.select-input.open:not(.in-trans) .material-input {
    -webkit-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    -moz-box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    box-shadow: 0 1px 4px rgba(32, 33, 36, 0.18), 0 1px 26px rgba(32, 33, 36, 0.08);
    border-radius: 9px 9px 0 0;
}

.select-input.open:not(.in-trans) .material-label {
    border-radius: 9px 9px 0 0;
    border-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.restricted-write-access {
    cursor: not-allowed;
}

.restricted-write-access > * {
    pointer-events: none;
}

.restricted-write-access button {
    background-color: #ddd !important;
}

html body {
    font: 14px Mulish, Gibson, HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    color: #10185a;
    margin: 0;
    overflow-anchor: none;
}

html body a {
    font-family: Mulish, sans-serif;
}

body h1,
body h1,
body h2,
body .h2,
body h3,
body .h3,
body h4,
body .h4,
body h5,
body .h5,
body h6,
body .h6,
.poppins {
    font-family: 'Poppins', Tahoma, Verdana, Arial, HelveticaNeue-Light, "'Helvetica Neue Light'",
    "'Helvetica Neue'", Helvetica, Arial, "'Lucida Grande'", sans-serif;
    color: #10185a;
    font-weight: bold;
    line-height: 1.25;
}

h1 em,
h2 em,
h3 em,
h4 em {
    color: #5788ff;
    font-style: normal;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

i.material-icons {
    color: #10185a;
}

.h2 {
    font-size: 24px;
}

.h3 {
    font-size: 18px;
    line-height: 27px;
}

a:focus {
    outline: 0;
}

body small {
    font-size: 85%;
}

.material-symbols-outlined {
    max-width: 1em;
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    margin: 0;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

h6 {
    font-size: 14px;
}

a:hover {
    color: inherit;
}

@media (max-width: 768px) {
    .h2 {
        font-size: 18px;
    }

    .h3 {
        font-size: 14px;
        line-height: 21px;
    }
}
