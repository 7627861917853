.root {
    /*border-bottom : 1px solid #DADCE0;*/
    width: 100%;
    text-align: center;
}
.wrapper {
    margin: 0;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: auto;
}
.item,
.itemSelected {
    display: inline-block;
    white-space: nowrap;
    padding: 12px 12px;
    font-weight: bold;
    font-size: 14px;
    position: relative;
}
@media (min-width: 768px) {
    .item,
    .itemSelected {
        font-size: 18px;
        padding: 12px 32px;
    }
}

.item:after,
.itemSelected:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    transition: 0.2s ease;
    transform: scaleX(0);
}
.item.left:after,
.itemSelected.left:after {
    transform-origin: left;
}
.item.right:after,
.itemSelected.right:after {
    transform-origin: right;
}
.item.center:after,
.itemSelected.center:after {
    transform-origin: center;
}

.item small,
.itemSelected small {
    color: rgba(0, 0, 0, 0.56);
    display: block;
    text-align: left;
    font-weight: normal;
}
.itemSelected {
    color: #0077cc;
}
.itemSelected:after {
    background-color: #0077cc;
    transform: scaleX(1);
}
