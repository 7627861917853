.modal {
    display: flex;
    /* Probably need media queries here */
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    overflow: auto;
    left: 0;
    position: fixed;
    z-index: 101;
    background-color: rgba(16, 24, 90, 0.9);
    overflow-y: auto;
    align-content: center;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    flex: 1;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS
     Fixes odd IE bug with the requestform, the form has 0 width when there's vertical overflow in the modal
    */
    .modal {
        overflow-y: scroll;
    }
}

.local {
    position: absolute;
    left: auto;
    top: auto;
    width: auto;
    height: auto;
    padding: 0;
    background-color: transparent;
}

.modalInside {
    top: 0;
    border-radius: 5px;
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.2), 0 0 25px rgba(0, 0, 0, 0.15);
    left: 0;
    width: 100%;
    height: auto;
    max-width: 500px;
    position: relative;
    margin: auto;
    min-height: 130px;
    background-color: #fff;
    overflow: hidden;
}

.in .modalInside {
    transform: scale(0);
    opacity: 0;
    width: 100%;
    transform-origin: center center;
}

.in .modal {
    opacity: 0;
}

.inActive .modalInside {
    transform: scale(1);
    opacity: 1;
    overflow: hidden;
    transition: transform 300ms ease, opacity 300ms ease-out 0ms;
}

.inActive div {
    overflow: hidden !important;
}

.inActive .modal {
    opacity: 1;
    transition: opacity 100ms ease;
}

.out .modalInside {
    transform: scale(1);
    transform-origin: center center;
}

.outActive .modalInside {
    transform: scale(0);
    opacity: 0.5;
    transition: transform 300ms ease-in 90ms, opacity 300ms ease-in;
}

.out .modal {
    opacity: 1;
}

.outActive .modal {
    transition: opacity 200ms ease;
    opacity: 0;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.closeButtonWithPrefix {
    display: flex;
    align-items: center;
}

.closeButton button {
    padding: 10px;
}

.closeButton i {
    color: #dadada;
}

.closeButton button:hover i {
    color: #000;
}

.darkBtn .closeButton i {
    color: #10185a;
}

.darkBtn .closeButton button:hover i {
    color: #fff;
}

.content {
    padding: 24px;
    box-sizing: border-box;
    overflow-x: hidden;
}

@media (max-width: 767px) {
    .modal.fullScreen {
        padding: 0;
    }

    .modal.fullScreen .modalInside {
        min-height: 100%;
        border-radius: 0;
        background-color: #fff !important;
    }
}

/*if pwa and full height, add safe margin bottom*/
@media (display-mode: standalone) and (min-height: 100vh) {
    .modalInside {
        padding-bottom: 24px;
    }
}
