.root {
    font-size: 16px;
}

.border {
    position: relative;
}

.border:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #dadce0;
}

.nop {
    padding-top: 0;
    padding-bottom: 0;
}

.one {
    padding-top: 1px;
    padding-bottom: 0;
}

.xxs {
    padding-top: 1px;
    padding-bottom: 1px;
}

.xs {
    padding-top: 2px;
    padding-bottom: 2px;
}

.s {
    padding-top: 4px;
    padding-bottom: 4px;
}

.sm {
    padding-top: 6px;
    padding-bottom: 6px;
}

.m {
    padding-top: 8px;
    padding-bottom: 8px;
}

.ml {
    padding-top: 12px;
    padding-bottom: 12px;
}

.l {
    padding-top: 16px;
    padding-bottom: 16px;
}

.xl {
    padding-top: 24px;
    padding-bottom: 24px;
}

.xxl {
    padding-top: 30px;
    padding-bottom: 30px;
}

.xxxl {
    padding-top: 48px;
    padding-bottom: 48px;
}

.nxxl {
    padding-top: 20px;
    padding-bottom: 20px;
}

.nxxl2 {
    padding-top: 32px;
    padding-bottom: 32px;
}

@media (max-width: 767px) {
    .mob_xs {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .mob_s {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .mob_sm {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .mob_m {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .mob_ml {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .mob_l {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .mob_xl {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .mob_nxxl {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .mob_nxxl2 {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}
