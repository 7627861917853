.toggles {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
}
.toggles label,
.toggles label div {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buttonRow {
    position: sticky;
    top: 10px;
    z-index: 22;
}

.details {
    display: flex;
    column-gap: 16px;
}
