.hoverText {
    position: relative;
    display: inline-block;
    user-select: none;
}

.hoverText .text {
    cursor: help;
}

.hoverText .text:hover {
    color: #132628;
}

.wrapper {
    position: relative;
    display: inline-block;
    height: 100%;
}

.hoverText .bubble {
    display: none;
    position: absolute;
    padding: 16px;
    color: #10185a;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
    z-index: 11;
    background-color: #ffe6c8;
    text-align: left;
    white-space: normal;
    line-height: 1.3;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    user-select: text;
}

.hoverText .bubble.whiteBg {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 40px hsla(0, 0%, 0%, 0.2);
    -moz-box-shadow: 0 2px 40px hsla(0, 0%, 0%, 0.2);
    box-shadow: 0 2px 40px hsla(0, 0%, 0%, 0.2);
}

.hoverText .bubble.defaultFont {
    font-family: Mulish, 'Work Sans', Helvetica, Arial, serif;
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0;
}

.hoverText.showBubble .bubble {
    display: block;
    width: 320px;
    max-width: calc(100vw - 32px);
}

.tail {
    position: absolute;
    z-index: 12;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
}

.tail.bottom {
    bottom: -15px;
    border-bottom: 10px solid #ffe6c8;
}

.tail.bottom.whiteBg {
    border-bottom: 10px solid #fff;
}

.tail.top {
    top: -15px;
    border-top: 10px solid #ffe6c8;
}

.tail.top.whiteBg {
    border-top: 10px solid #fff;
}
