@value border, primary, primaryBright, textLight from '@advanza/css/vars.css';

.edit {
}

.header {
    color: textLight;
}

.header h3 {
    padding-left: 12px;
    font-size: 16px;
    /*font-weight : normal;*/
}

.editable {
    border: 1px dashed border;
    border-radius: 8px;
    padding: 24px 12px;
}

.description p > span:not(:first-of-type) {
    margin-top: 1.1em;
    line-height: 16px;
    display: inline-block;
}

.dropZoneInput {
    border: 1px dashed border;
    color: textLight;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
    padding: 12px;
}

.dropPlaceholder {
    text-align: center;
}

img {
    image-orientation: from-image;
}

.dropZoneImage {
    padding: 3px;
}
.fileEntity {
    position: relative;
    overflow: hidden;
}
.buttonRow {
    padding: 5px;
    transition: 0.1s ease;
    position: absolute;
    top: -50px;
    left: 0;
    margin: 0;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
}
.buttonRow i {
    text-shadow: 0 0 5px #000;
    color: #fff;
}
.fileEntity .buttonRow {
    opacity: 1;
    top: 0;
}
@media (pointer: coarse) {
    .buttonRow {
        opacity: 1;
        top: 0;
    }
    .buttonRow button {
        transform: scale(1.2);
    }
}
button.red i {
    color: #a75151;
}

.imageInput {
    margin-top: -20px;
}
.fileEntity input {
    background-color: #fff;
}

.busy {
    opacity: 0.4;
    transition: opacity 0.2s ease;
}
