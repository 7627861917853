.root {
    position: fixed;
    bottom: 0;
    overflow: hidden;
    left: 8px;
    right: 8px;
    margin: auto;
    z-index: 9999;
    max-width: 450px;
}

.toast {
    position: relative;
    max-width: none;
    overflow-wrap: anywhere;
}

.toast:nth-last-child(n + 2) {
    margin-bottom: 8px;
    border-radius: 5px !important;
}
