.root {
    text-align: center;
}

.boxButton {
    display: flex;
    width: 100%;
    height: 75px;
    padding: 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px dashed #aaaab0;
    background: #f7f7f7;
    transition: border-color 0.5s ease 0s;
}

.boxButton.found {
    border: none;
}

.iconSelectedBlock {
    display: none;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #eff8ff;
}

.iconSelectedBlock i {
    color: #5788ff !important;
    font-size: 16px;
}

.boxButton:hover .iconSelectedBlock {
    display: flex;
}

.boxButton.isCurrent:not(:disabled) {
    border-color: #5788ff;
    background: #eff8ff;
}

.boxButton .title {
    font-size: 12px;
    line-height: normal;
}

.boxButton .icon {
    font-size: 16px;
}

.boxButton .title,
.boxButton .icon {
    color: #aaaab0;
    transition: color 0.5s ease 0s;
}

.boxButton.isCurrent:not(:disabled) .title,
.boxButton.isCurrent:not(:disabled) .icon {
    color: #5788ff;
}

.boxButton:not(:disabled):hover,
.boxButton:not(:disabled):hover .title,
.boxButton:not(:disabled):hover .icon {
    border-color: #50575b;
    color: #50575b;
}

.boxButton.isCurrent:not(:disabled):hover,
.boxButton.isCurrent:not(:disabled):hover .title,
.boxButton.isCurrent:not(:disabled):hover .icon {
    border-color: #000e80;
    color: #000e80;
}

.bottom {
    height: 18px;
}

.foundLine {
    display: inline-flex;
    font-size: 12px;
    line-height: normal;
}

.foundLine i {
    display: flex;
    width: 12px;
    max-width: 12px;
    height: 12px;
    padding-left: 1px;
    align-items: center;
    border-radius: 100%;
    background: #0ea66b;
    font-size: 9px;
    color: white;
}

.notFoundButton {
    color: #5788ff;
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: normal;
}
