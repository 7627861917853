/* Define keyframes for the shadow animation */
@keyframes shadowAnimation {
    0%, 100% {
        box-shadow: 0 0 20px rgba(87, 136, 255, 0.2);
    }
    22% {
        box-shadow: 0 0 33px rgba(87, 136, 255, 0.3)
    }
    33% {
        box-shadow: 0 0 20px rgba(241, 97, 42, 0.3);
    }
    66% {
        box-shadow: 0 0 33px rgba(241, 97, 42, 0.3);
    }88% {
        box-shadow: 0 0 44px rgba(241, 97, 42, 0.4);
    }
}

.aurora {
    box-shadow: 0 0 44px rgba(87, 136, 255, 0.4);
    animation: shadowAnimation 4s infinite linear;
    border: none;
}

/* Define keyframes for the shadow animation */
@keyframes shadowAnimationAlarming {
    0%, 100% {
        box-shadow: 0 0 30px rgba(255, 0, 0, 0.4);
    }
    22% {
        box-shadow: 0 0 43px rgba(241, 97, 42, 0.4)
    }
    33% {
        box-shadow: 0 0 30px rgba(241, 42, 42, 0.4);
    }
    66% {
        box-shadow: 0 0 43px rgba(255, 2, 154, 0.4);
    }88% {
         box-shadow: 0 0 54px rgba(241, 97, 42, 0.4);
     }
}

.auroraAlarming {
    box-shadow: 0 0 44px rgba(255, 0, 0, 0.3);
    animation: shadowAnimationAlarming 4s infinite linear;
    border: none;
}