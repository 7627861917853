.container {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
    max-width: 450px;
}

.fixed {
    position: fixed;
    z-index: 999;
}

.root {
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: #0b1a37;
    color: #fff;
    padding: 8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}

.red .root {
    background-color: #e74c3c;
}

.orange .root {
    background-color: #ff7d3c;
}

.green .root {
    background-color: #0ea66b;
}

.root i,
.root a {
    color: #fff;
}

.root a {
    text-decoration: underline;
}

.body {
    padding: 0px 6px;
}

.closeBtn {
    opacity: 0.7;
}

.enter {
    opacity: 0;
    transform: translateY(100%);
}

.fromTopEnter {
    opacity: 0;
    transform: translateY(-10%);
}

.enterActive {
    transition: 0.3s ease 0s;
    transform: translateY(0%);
    opacity: 1;
}

.exit {
    opacity: 1;
}

.exitActive {
    opacity: 0;
    transition: 0.5s ease 0s;
}

@media (max-width: 768px) {
    .container {
        position: fixed;
    }
}
