@value primary from '@advanza/css/vars.css';

.radios:not(.inline) > label {
    padding: 5px 0;
}

.label {
    cursor: pointer;
    display: block;
}

.radio {
    display: none;
}

.label {
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.label.disabled {
    cursor: not-allowed;
}

.label.disabled {
    opacity: 0.6;
}

.inline {
    display: flex;
}

.inline > .label:not(:last-of-type) {
    margin-right: 1.5em;
}

.radio {
    display: none;
}

.label:hover .icon,
.label:hover .icon i {
    border-color: #000;
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.05);
    transition: 0.11s ease;
}

.label:hover .icon:after {
    background-color: #000;
}

.icon,
.iconChecked {
    width: 15px;
    min-width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid rgb(60, 64, 67);
    margin-right: 12px;
    line-height: 12px;
    text-align: center;
    box-sizing: content-box;
}

.radios i {
    color: rgb(60, 64, 67);
    border-radius: 50%;
}

.radios .light i {
    color: rgba(0, 0, 0, 0.54);
}

.radios .label.checked i {
    color: #0077cc;
}

.iconChecked {
    border-color: #0077cc;
}

.icon:after,
.iconChecked:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    transform: scale(0);
    opacity: 0;
    background-color: #0077cc;
    transition: 0.2s ease;
}

.iconChecked:after {
    opacity: 1;
    transform: scale(1);
}

.error {
    color: red;
}

.error i {
    color: red;
}

@media (max-width: 768px) {
    .radios:not(.inline) > label {
        padding: 10px 0;
    }
}

.colorPicker .icon,
.colorPicker .iconChecked {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 0;
}

.colorPicker .icon::after,
.colorPicker .iconChecked::after {
    display: none;
}

.colorPicker .icon {
    border: none;
}

.colorPicker .iconChecked {
    border-color: #10185a;
}

.colorPicker.inline > .label:not(:last-of-type) {
    margin-right: 8px;
}

.inBlocks {
    display: inline-block;
}

.inBlocks.radios:not(.inline) > label {
    padding: 16px;
    border-radius: 5px;
    background: white;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    font-family: Mulish, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.inBlocks.radios:not(.inline) > label:not(:first-child) {
    margin-top: 16px;
}

.inBlocks.radios:not(.inline) > label:hover {
    transition: 0.2s ease;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}

.inBlocks .icon,
.inBlocks .iconChecked,
.inBlocks .label:hover .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border-color: #10185a;
    background-color: white;
}

.inBlocks .icon::after,
.inBlocks .iconChecked::after {
    background-color: #10185a;
}

.appearance_bold,
.appearance_blocks {
    .icon,
    .iconChecked {
        margin-right: 8px;
        width: 12px;
        height: 12px;
        min-width: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: primary;
    }

    .icon::after,
    .iconChecked::after {
        width: 8px;
        height: 8px;
        background-color: primary;
    }

    .label > span {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
    }
}
