.root {
    text-transform: uppercase;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 1.2px;
    color: #10185a;
}
.margin {
    margin-bottom: 10px;
    display: block;
}
