.root {
    flex-wrap: nowrap;
    margin-bottom: -22px;
}

.col {
    padding-right: 0.5rem;
}

.colFirst {
    padding-left: 0.5rem;
}
