.root > div {
    display: flex;
    align-items: center;
}
.root svg {
    width: 50px;
    margin: 6px 10px 6px 0;
}

.root > div > div {
    width: auto;
}
