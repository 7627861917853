.root textarea {
    height: 200px;
    background-color: #fff;
    width: auto;
    min-width: 750px;
    max-width: 100%;
    border-radius: 5px;
    border: 1px solid #dadada;
}

.invalid {
    color: red;
}