.toggle {
    padding: 8px 14px;
    border-radius: 5px;
    background: white;
    box-shadow: 0 2px 17px 0 rgba(182, 185, 205, 0.32);
}

.toggleTitle {
    font-weight: 600;
    line-height: normal;
}

.toggleTitle i {
    color: #5788ff;
}

.ruleRow {
    padding: 8px 0;
    flex-wrap: nowrap;
    font-size: 12px;
}

.ruleRow:not(:last-child) {
    border-bottom: 1px solid #dbdce0;
}

.grow {
    flex-grow: 1;
}

.casesRow {
    row-gap: 8px;
}

.caseCol {
    flex-grow: 1;
    white-space: nowrap;
}

.selected {
    font-weight: 700;
}
