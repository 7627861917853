.row {
    display: inline-flex;
    grid-gap: 16px;
    margin-top: 16px;
    width: 100%;
}
.row label {
    max-width: 400px;
}

.root.loading {
    opacity: 0.5;
}
.root table tr {
    cursor: pointer;
}
.root table th{
    text-align: left;
}
.edit {
    margin: 24px 0;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding: 16px 0 30px 0;
}
.edit > b {
    text-shadow: 0 0 10px rgba(0,0,0,0.2);
    font-size: 18px;
    
}