.root {
    width: 33%;
    justify-content: space-around;
}

.circularChart {
    display: block;
    margin: 10px auto;
    max-width: 100px;
    max-height: 250px;
}

.circleBg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: all 0.2s ease;
    /*animation: progress 1s ease-out forwards;*/
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.circularChart.orange .circle {
    stroke: #ff9f00;
}

.circularChart.green .circle {
    stroke: #4cc790;
}

.circularChart.blue .circle {
    stroke: #3c9ee5;
}

.percentage {
    fill: #666;
    font-family: Poppins, Mulish, sans-serif;
    font-size: 0.6em;
    text-anchor: middle;
}
