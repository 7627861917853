.root {
    display: flex;
    flex-direction: column;
    transition: background-color 150ms linear;
}
.root button {
    border-radius: 5px;
}
.root button i {
    color: rgba(16, 24, 90,0.5)
}
.root button:hover {
    background-color: rgba(16, 24, 90,0.08)
}
.root button:hover i {
    color: #10185a;
}