
.root > div {
    display: flex;
}

.label {
    opacity: 0.3
;
    transition: all 0.16s ease;
    filter: grayscale(50%);
    margin-right : 14px !important;
    order: 1;
}
.label svg {
    height: 15px;
    width: 25px;
}
.checked, .label:hover {
    opacity: 1;
    filter: grayscale(0%);
    transform: scale(1.2);
}
.checked svg, .label:hover svg {
    box-shadow: 0 2px 12px rgba(0,0,0,0.2);
}
.checked {
    order : 0;
    transform: scale(1);
}

.label > div {
    display: none;
}