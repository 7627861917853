.subTitle {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.44px;
    line-height: 14px;
}

.bold {
    font-weight: bold;
    letter-spacing: 1.2px;
}
