.table {

}
.table td {
    padding: 16px;
}
thead td {
    background-color: rgba(224, 224, 224, 0.84);
    color: #232323;
    font-weight: 700;
}
.table tbody > tr:not(:first-of-type) td {
    border-top: 1px solid #dadada;
}
