.root input {
    min-width: 280px !important;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 12px;
}

.eventSelect {
    min-width: 100px;
}

.button {
    height: 44px;
    min-width: 140px;
    font-weight: bold;
}

.confirmationText strong {
    font-size: 14px;
    font-weight: bold;
}
