.score {
    margin-top: 2px;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.level2.first {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    border-top: 1px solid #a5a5a5;
    padding-top: 10px;
}

.level2.first:first-child {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
}

.level2.first > div {
    flex-basis: initial;
    max-width: initial;
}

.level2.first > div:first-child {
    padding-right: 0;
}

.level2:not(.first) > div:first-child:nth-last-child(2) {
    flex-basis: 66.66%;
    max-width: 66.66%;
}

.level2:not(.first) > div:last-child:nth-child(2) {
    flex-basis: 33.33%;
    max-width: 33.33%;
}

.level3.first {
    text-decoration: underline;
    font-weight: bold;
    font-size: 16px;
    margin-top: 15px;
}
