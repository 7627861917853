@value border, textLight from '@advanza/css/vars.css';

.message {
    background-color: #fff;
    border-radius: 8px;
    padding: 9px 16px;
    border: 1px solid border;
    line-height: 1.5;
    min-width: 100px;
    position: relative;
}
.message.mine:after {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 8px solid #fff;
    border-bottom: 0px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    right: -5px;
    top: 0;
}
.mine.message {
    background-color: #d4e3ef;
    border: none;
    border-top-right-radius: 2px;
}
.mine.message:after {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 8px solid #d4e3ef;
    border-bottom: 0px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    right: -5px;
    top: 0;
}
.msgTime {
    color: textLight;
    font-size: 12px;
    padding: 0 16px;
}
.mine .msgTime {
    text-align: right;
}
.attachment {
    overflow-wrap: anywhere;
}
