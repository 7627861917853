.progress > div {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 8px;
    margin: 10px 0;
}

.progress > div > div {
    background-color: #f1612a;
    height: 100%;
    transition: 0.15s ease;
    border-radius: 4px;
}

.newStyle > div {
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0;
}

.newStyle > div > div {
    background-color: #5788ff;
    border-radius: 0;
}
