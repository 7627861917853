@value grey-700 from '@advanza/css/vars.css';

.infoBtn {
    position: relative;
    z-index: 3;
    margin-left: 5px;
}

.dropZoneWrapper {
    user-select: none;
}

.dropZoneInput {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px dashed #c0c1c7;
    cursor: pointer;
    border-radius: 5.3px;
    height: inherit;
}

.dropZoneInput.error {
    border: 1px solid #f00;
}

.missingPhotoError {
    font-weight: 800;
    color: #f00;
}

.warning {
    color: #f00;
}

.smallPaddingRow {
    margin-left: -4px;
    margin-right: -4px;
}

.smallPaddingRow > div {
    padding-left: 4px;
    padding-right: 4px;
}

@media (min-width: 768px) {
    .smallPaddingRow {
        margin-left: -6px;
        margin-right: -6px;
    }

    .smallPaddingRow > div {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.expandBtn {
    position: relative;
    color: #5788ff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.expandBtn::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: #5788ff;
}

@media (max-width: 499px) {
    .profilePhotosEditRow {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.drag {
    color: grey-700;
    font-style: italic;
}
