.radios:not(.inline) label {
    padding: 5px 0;
}

.itemContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.itemContainer label {
    width: 100%;
    margin-right: 10px;
}
.label {
    cursor: pointer;
    display: block;
}
.label i {
    font-size: 21px;
}
.label > div {
    margin-right: 0.5rem;
}

.radio {
    display: none;
}

.label {
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.inline {
    display: flex;
}

.inline > .label:not(:last-of-type) {
    margin-right: 1.5em;
}

.radio {
    display: none;
}

.error i,
.error {
    color: red;
    font-weight: bold;
}

@media (max-width: 768px) {
    .radios:not(.inline) > label {
        padding: 10px 0;
    }
}
