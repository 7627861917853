.view .xsell {
    display: inline-block;
    padding-right: 20px;
}

.serviceName {
    font-size: 13px;
}

.serviceName.primary {
    font-weight: bold;
}

.edit .service {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.edit .checkbox {
    display: inline-block;
    border-radius: 2px;
    border: 1px solid #000;
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.edit .warning {
    color: #f00;
    margin-right: auto;
}
