.newBooster  {
    display: flex;
    grid-gap: 11px;
    flex-wrap: nowrap;
    align-items: center;
}
.newBooster > div:last-of-type {
    margin-left: auto;
}
.newBooster:hover {
    background-color: #f5f5f5;
}

.loading {
    opacity: 0.5;
    pointer-events: none;
    cursor: wait;
}