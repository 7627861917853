.notificationSettings {
    display: grid;
    grid-template-columns: auto 80px;
    align-items: center;
    gap: 12px 4px;
    max-width: 300px;
}

.showMoreFields {
    border: 1px solid #dadce0;
}

.showMoreFields i {
    color: #3b6eea;
}
