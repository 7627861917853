.root {
    display: grid;
    padding: 14px;
    grid-gap: 8px;
}

.old {
    text-decoration: line-through;
    background-color: #ff00831c;
}
.new {
    font-weight: 600;
    background-color: rgba(72, 255, 0, 0.11);
}
.old, .new {
    border-radius: 5px;
    padding: 2px;
}

.item {
    display: inline-flex;
    align-items: center;
}
.item > b:first-child {
    width: 150px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
}
.item > *:not(:last-child) {
    margin-right : 8px;
}
.arrow {
    text-align: center;
}