.rowAutomatedCheckActive td {
    position: relative;
    z-index: 1;
}

.rowAutomatedCheckActive {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.acceptedHover {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 12px;
}
