.list {
    padding: 0 24px;
}

.listItem {
    z-index: 102;
    padding: 5px 12px;
    margin: 5px 0;
    border-radius: 5.3px;
    border: 1px solid #ccc;
    cursor: grab;
}

.listItem.isDragged {
    cursor: grabbing;
}

.listItemRow {
    flex-wrap: nowrap;
}

.number {
    font-size: 24px;
}
