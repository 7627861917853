.root a,
.root a i {
    color: #5788ff;
}

.providerLink {
    margin-left: 8px;
}

.infoBlock {
    font-size: 20px;
}

.infoBlock .input {
    max-width: 400px;
}
