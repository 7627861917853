.root {
    font-weight: 600;
}
.root i {
    color: #898e91;
}
.root.checked i {
    color: #fff;
}

.root.error {
    border: 1px solid #cf3131;
}

.errorMsg {
    color: #cf3131;
    font-size: 13px;
    line-height: 1;
}
