.icon {
    color: #10185a;
    vertical-align: middle;
    user-select: none;
}

.icon.fill {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.icon.colorPrimary {
    color: #5788ff;
}

.icon.colorWhite {
    color: #fff;
}

.icon.colorCta {
    color: #5788ff;
}

.icon.colorWarning {
    color: #cf3131;
}

.icon.black {
    color: #000;
}

.icon.green {
    color: #0ea66b;
}

.icon.red {
    color: #f42435;
}

.icon.orange {
    color: #f46511;
}

.icon.yellow {
    color: #f6b500;
}

.icon.grey {
    color: rgba(0, 0, 0, 0.23);
}

.icon.inherit {
    color: inherit;
}
