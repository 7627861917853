
.root {
    position: relative;
}

.root .hidden {
    color: #898e91;
}

.projectOrder {
    position: absolute;
    right: 0;
    top: -30px;
}

.projectRow {
    display: grid;
    grid-template-columns: 1fr auto;
}

.grid {
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: center;
    align-items: center;
    cursor: pointer;
}

.grid > div {
    padding: 6px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.grid .projectId {
    min-width: 50px;
    color: #5f6368;
}

.grid .projectName {
    overflow: hidden;
}

.searchLocation {
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: #10185a;
    margin: 2px 0 4px;
}

.profileProjectsEntity select {
    height: 46px !important;
}

.warningText {
    color: #f00;
}