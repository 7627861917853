.grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 12px 24px;

    .divider {
        grid-column: 1 / -1;
        height: 1px;
        background-color: #ccc;
    }
}
