.root {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    min-height: 100vh;
    position: fixed;
    z-index: 2;
    background-color: transparent;
}

@keyframes breath {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.15;
    }
}

.isTest:after {
    content: '';
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 100%;
    background: url('https://static.trustoo.nl/misc/warning.jpg') repeat center center / contain;
    top: 0;
    animation-duration: 3s;
    animation-name: breath;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    opacity: 0.15;
}
.isTest.alt:after {
    background: url('https://static.trustoo.nl/app/dev/icons/android-chrome-512x512.png') repeat center center / contain;
}

.collapsed {
    max-width: 50px;
    overflow: hidden;
}

.collapsed .logo img {
    display: none;
}
.logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.padding {
    padding: 0 24px;
}

.active {
    color: #0077cc;
}

.root a {
    display: block;
    padding: 8px 16px;
}

.content {
    display: flex;
    align-items: flex-start;
    gap: 14px;
    flex-wrap: nowrap;
    padding: 15px 20px;
}

.root a:hover {
    color: #0077cc;
}

.open {
    font-weight: bold;
}

.subs {
    padding-left: 43px;
}

.logo,
.countryToggle {
    padding: 8px 16px;
}

.toggles > div > div {
    border-bottom: none !important;
}
.toggles {
    max-height: calc(100vh - 110px);
    overflow: auto;
}

.root h4 {
    font-weight: normal;
}
