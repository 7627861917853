.root {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    padding: 10px 16px 7px 16px;
    margin-bottom: 10px;
    border-bottom: 4px solid transparent;
}
.highlight {
    border-bottom: 4px solid #0077cc;
    font-weight: bold;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.alt {
    background-color: #eee;
}
.root b {
    font-size: 26px;
    margin: 5px 0;
    display: block;
    font-weight: normal;
}
.loading {
    opacity: 0.6;
    transition: opacity 0.2s ease;
}
.root .name {
    color: rgba(0, 0, 0, 0.56);
}

.icon {
}

.prev {
    color: rgba(0, 0, 0, 0.56);
    font-size: 14px;
}
.prev i {
    padding-right: 5px;
    vertical-align: middle;
}
.prev .noDiff {
    color: rgba(0, 0, 0, 0.56);
}
.prev .better,
.better i {
    color: #12c33e;
}
.prev .worse,
.worse i {
    color: #f42435;
}
