.root {
    position: relative;
}

.root label {
    cursor: text;
}

.enter {
    transform: scale(0);
    opacity: 0;
}

.enterActive {
    transform: scale(1);
    transition: transform 0.2s ease-out, opacity 0.2s ease-in 0.1s;
    opacity: 1;
}

.exitActive {
    transform: scale(0);
    transition: transform 0.2s ease-in 0.1s, opacity 0.1s ease-in;
    opacity: 0;
}

.picker {
    transform-origin: top left;
    z-index: 99;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    min-width: 100px;
    padding: 12px 0px 12px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
    background-color: #fff;
    height: 100vh;
    overflow: hidden;
}

.withGroups .picker {
    overflow-y: auto;
}

.altStyle .picker {
    padding: 10px 0px 10px;
    border-radius: 4px;
}

.picker > div:first-of-type {
    border-bottom: 1px solid #dadce0;
}

.empty .picker > div:first-of-type {
    border: none;
}

.options {
    position: relative;
    border: 1px solid #dadce0;
    margin: 10px;
    overflow-y: auto;
}

.withGroups .options {
    max-height: 470px;
}

.options:empty {
    border: none;
    border-bottom: 1px solid #dadce0;
}

.altStyle .options:empty {
    border: none;
}

.options .item {
    padding: 11px 20px 11px 14px;
    font-size: 16px;
    text-transform: capitalize;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    color: rgb(60, 64, 67);
}
.options .item small {
    text-transform: none;
    color: rgba(60, 64, 67, 0.64);
}
.moreButton {
    color: #5788ff;
    width: 100%;
    font-weight: bold;
    margin-bottom: 15px;
}
.popularItem {
    cursor: pointer;
    border: 1px solid #b6b9cd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-right: 6px;
}

.moreButton i {
    color: #5788ff;
    font-size: 20px;
}

.useLeftPadding .options .item {
    padding-left: 55px;
}

.backCol {
    margin-left: 16px;
    padding-top: 0;
}

.optionsWithGroups {
    position: relative;
    padding: 0 16px 92px 16px;
    max-width: 100vw;
}

.optionsWithGroups b {
    color: #10185a;
}
.optionsWithGroups .item {
    padding: 6px 14px;
    margin-bottom: 10px;
    border: 1px solid #b6b9cd;
    border-radius: 3px;
    display: block;
    margin-right: 6px;
}
.inline .optionsWithGroups .item {
    display: inline-block;
}

.item {
    cursor: pointer;
}

.item:hover {
    color: #0f497d;
    text-decoration: underline;
}

.options .item:hover,
.options .item:focus {
    color: inherit;
    text-decoration: none;
    cursor: default;
    background-color: rgba(0, 0, 0, 0.03);
}

.subName {
    display: block;
    color: #999;
}

.root > div input {
    pointer-events: none;
}

.picker input:focus {
    border-color: #40457b;
}

.root .picker label {
    border: none;
    background-color: #fff;
    border-radius: 0;
}

.root .picker input {
    padding: 12px 20px;
}

.root.altStyle .picker input {
    padding: 25px 17px;
}

.selectTriangle {
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 33%;
    width: 9px;
    height: 9px;
    background: transparent;
    border-bottom: 2px solid #10185a;
    border-left: 2px solid #10185a;
    transform: rotate(-45deg);
}

.error {
    color: red;
    font-weight: bold;
}

.error i {
    color: red !important;
}

.hasError label {
    border-color: red;
}

.hasError.focused .placeholder {
    color: red;
}

@media (min-width: 768px) {
    .optionsWithGroups {
        padding: 0 16px;
    }
    .picker {
        z-index: 99;
        width: 100%;
        max-width: 575px;
        position: absolute;
        min-width: 100px;
        top: -12px;
        padding: 12px 0px 12px;
        left: -5px;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
    }

    .altStyle .picker {
        top: -4px;
        left: 0;
        padding: 0 0 4px;
    }

    .root .picker label {
        border-bottom: 1px solid #dadce0;
    }

    .backCol {
        display: none;
    }
    .options {
        border: none;
        margin: 0;
    }
    .optionsWithGroups .item {
        padding: 0;
        margin-bottom: 5px;
        border: none;
    }
}

@media (max-width: 767px) {
    .picker {
        max-height: 100% !important;
        left: 0 !important;
    }
}
