.black {
    background-color: #000;
}

.red {
    background-color: #ff4545;
}

.yellow {
    background-color: #ffee00;
}

.orange {
    background-color: #ffd5b3;
}

.blue {
    background-color: #bde8ff;
}

.magenta {
    background-color: #ffd6d6;
}

.darkblue {
    background-color: blue;
}

.green {
    background-color: #b8ffad;
}

.greenBright {
    background-color: #68c33d;
}

.greenWhatsapp {
    background-color: #29a71a;
}

.purple {
    background-color: #d8d6ff;
}
