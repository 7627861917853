.new {
    border-radius: 5px;
    box-shadow: 0 2px 17px 0 rgba(182, 185, 205, 0.32);
    padding: 16px;
}

.item {
    padding: 6px 0;
}

.old {
    text-decoration: line-through;
}

.active {
    color: mediumseagreen;
}

.delete {
    margin-right: 0;
    margin-left: auto;
}
