.editBtn {
    position: relative;
    color: #5788ff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.editBtn:disabled {
    color: rgba(200, 200, 200, 0.7);
    cursor: not-allowed;
}

.editBtn::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: #5788ff;
}

.editBtn:disabled::after {
    background-color: rgba(200, 200, 200, 0.7);
}

.editBtn i {
    margin-left: 7px;
}

.editBtn:disabled i {
    color: rgba(200, 200, 200, 0.7);
}

.list {
    padding: 0 calc(50% - 100px);
}

.list.wider {
    padding: 0 calc(50% - 200px);
}

.listItem {
    z-index: 102;
    padding: 5px 12px;
    margin: 5px 0;
    border-radius: 5.3px;
    border: 1px solid #ccc;
    cursor: grab;
}

.listItem.isDragged {
    cursor: grabbing;
}

.listItemRow {
    flex-wrap: nowrap;
}

.number {
    font-size: 24px;
}
