.modal {
    display: flex;
    /* Probably need media queries here */
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    background: rgba(255, 255, 255, 0.92);
    overflow-y: auto;
    align-content: center;
    align-items: center;
    flex: 1;
}
.modalInside {
    top: 0;
    border-radius: 8px;
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.2), 0 0 25px rgba(0, 0, 0, 0.15);
    left: 0;
    width: 100%;
    height: auto;
    overflow: auto;
    max-width: 500px;
    position: relative;
    margin: auto;
    min-height: 130px;
    background-color: #fff;
}
.in .modalInside {
    transform: scale(0);
    opacity: 0;
    transform-origin: center center;
}
.in .modal {
    opacity: 0;
}
.inActive .modalInside {
    transform: scale(1);
    opacity: 1;
    overflow: hidden;
    transition: transform 300ms ease-out, opacity 300ms ease-out 90ms;
}
.inActive div {
    overflow: hidden !important;
}
.inActive .modal {
    opacity: 1;
    transition: opacity 200ms ease;
}

.out .modalInside {
    transform: scale(1);
    transform-origin: center center;
}
.outActive .modalInside {
    transform: scale(0);
    opacity: 0.5;
    transition: transform 300ms ease-in 90ms, opacity 300ms ease-in;
}
.out .modal {
    opacity: 1;
}
.outActive .modal {
    transition: opacity 200ms ease;
    opacity: 0;
}
.closeButton {
    text-align: right;
    width: 100%;
    height: 30px;
    position: absolute;
    z-index: 1;
}
.hideButton .closeButton button {
    display: none;
}
.closeButton button:hover div {
    background-color: #000;
}
.closeButton button {
    margin-right: 20px;
    margin-left: auto;
    padding: 17px 10px 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.closeButton button > div {
    width: 2px;
    background-color: #dadada;
    height: 20px;
    cursor: pointer;
}

.closeButton button > div:first-of-type {
    position: absolute;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.content {
    padding: 24px;
    box-sizing: border-box;
    overflow-x: hidden;
}
