.root {
    display: flex;
    align-items: center;
}

.root > i,
.root > img:first-child {
    margin-right: 5px;
}

.root.center {
    justify-content: center;
}

.root.top {
    align-items: flex-start;
}

.root.overflowFix > i {
    overflow: visible;
}

.root.noWrap {
    white-space: nowrap;
}

.onTop {
    flex-direction: column;
    justify-content: center;
}
