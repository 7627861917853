.modalRoot {
    background-color: rgba(16, 24, 90, 0.5);
    padding: 16px;
    font-size: 14px;

    .heading {
        i {
            color: #f6b500;
        }
    }

    .subHeading {
        font-size: 16px;
        font-weight: 700;
    }
}
