.alert {
    position: fixed;
    top: 100px;
    right: 20px;
    z-index: 9999;
}

.alertBlock {
    background-color: red;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
}

.alertBlock button {
    margin-left: 20px;
}

.bgWhite {
    background-color: #fff;
}
