.more {
    display: none;
}
.expanded .more {
    display: block;
    margin-top: 16px;
    margin-bottom: 24px;
}
.data b {
    text-transform: capitalize;
}
.dataScores {
    padding: 0 14px;
}
.dataScores > div {
    display: flex;
    padding: 4px 0px;
}
.dataScores > div:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid #dadada;
}
.dataScores > div > * {
    width: 33%;
}
.score {
    font-weight: bold;
    font-size: 14px;
}
.total {
    margin-left: 14px;
}
.total > div {
    font-size: 18px;
    font-weight: bold;
}
.first .total > div {
    font-size: 32px;
}
.score {
    display: flex;
}
.score .compare {
    font-size: 10px;
    margin-left: 3px;
    font-weight: bold;
}

.zValue {
    font-weight: bold;
}
.date {
    margin-bottom: 4px;
    color: #555;
    font-weight: 600;
    cursor: pointer;
}
.main {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.date i {
    color: #555;
}
.scores {
    display: flex;
}
.scores > div {
    padding: 8px 14px;
    border-radius: 5px;
    margin-right: 4px;
    min-width: 85px;
    box-shadow: 0 2px 17px 0 rgba(182, 185, 205, 0.32);
}
