.waterMelonChatButton {
    display: inline-block;
    background-color: #fecc90;
    border-radius: 5px;
    font-size: 14px;
    line-height: 28px;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 0.4rem 0.5rem;
    cursor: pointer;
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.26);

    &.fixed {
        display: block;
        position: fixed;
        right: 15px;
        bottom: 20px;
        z-index: 8888;

        &.widgetIsOpen {
            display: none;
        }
    }

    &.block {
        display: block;
        border-radius: 0;
        padding: 14px 24px;
        box-shadow: none;
    }

    .row {
        margin: 0;

        .col {
            padding: 0;

            &.chatIcon {
                padding-right: 0.3rem;
            }

            &.expandIcon {
                padding-left: 0.3rem;
            }

            .noWhiteSpaceWrap {
                white-space: nowrap;
            }
        }
    }
}
