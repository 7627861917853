.root > .item:not(:last-of-type) .regionName {
    border-bottom: 1px solid #dadada;
    padding-bottom: 4px;
}

.item {
    padding: 4px 0;
}

.region {
    display: flex;
    width: 100%;
    align-items: flex-start;
    cursor: pointer;
}

.expanded {
    margin-left: 20px;
    margin-top: 14px;
    margin-bottom: 20px;
}

.expanded i {
    font-size: 19px;
}

.region i:hover,
.expanded button:hover i {
    box-shadow: 0 0 0 4px rgba(87, 136, 255, 0.2);
    background-color: rgba(87, 136, 255, 0.2);
    border-radius: 50%;
}

.regionName {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-left: 4px;
}

.map div[class*='gm-style-iw-a'] {
    display: none;
}

.map {
    position: relative;
}

.map .banner {
    text-align: center;
    width: calc(100% - 10px);
    padding: 6px 6px;
    position: absolute;
    z-index: 99;
    top: 5px;
    left: 5px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 17px 0 rgba(182, 185, 205, 0.62);
}

.map .banner:empty {
    display: none;
}

.banner i {
    vertical-align: middle;
    font-size: 12px;
}

.banner b {
    color: #3b6eea;
}
