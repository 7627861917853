.root {
    position: relative;
    min-height: 5px;
}

.wrapper {
    margin: auto;
    transition: min-height 0.2s;
}

.loading {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
}

.busy {
    overflow: hidden;
}

.enter,
.enterPrev {
    transform: translateX(5%);
    opacity: 0;
}

.enterPrev {
    transform: translateX(-5%);
}

.enterActive {
    transform: translateX(0%);
    transition-duration: 150ms;
    transform-origin: left top;
    opacity: 0.5;
    transition-property: all;
    transition-timing-function: ease-out;
}

.enterDone {
    opacity: 1;
    transition: opacity 0.1s;
}

.exit {
    transform: translateX(0%);
    overflow: hidden;
    opacity: 0.3;
}

.exitActive,
.exitActivePrev {
    transform: translateX(-5%);
    transform-origin: left bottom;
    opacity: 0;
    overflow: hidden;
    transition: transform 150ms ease, opacity 90ms ease, max-height 150ms ease;
}

.exitActivePrev {
    transform: translateX(5%);
}

/*alt animation*/
.enterAlt {
    transform: translateX(100%);
}

.enterPrevAlt {
    transform: translateX(-100%);
}

.enterActiveAlt {
    transform: translateX(0%);
    transition-duration: 150ms;
    transform-origin: right top;
    transition-property: all;
    transition-timing-function: linear;
}

.exitAlt {
    transform: translateX(0%);
}

.exitActiveAlt,
.exitActivePrevAlt {
    transform: translateX(-100%);
    transform-origin: left top;
    transition-timing-function: linear;
    transition-property: transform;
    transition-duration: 150ms;
}

.exitActivePrevAlt {
    transform: translateX(100%);
    transform-origin: right top;
}
