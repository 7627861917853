.root {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    border-top: 1px solid #dbdce0;
    background-color: white;
    padding: 24px calc(1rem + 24px + 14px) 24px calc(280px + 24px + 14px);
}

.row {
    flex-wrap: nowrap;
}

.firstCol {
    flex-grow: 1;
}

.firstColTop {
    display: flex;
    align-items: center;
}

.back {
    font-family: Mulish, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: -0.25px;
    color: #5788ff;
}

.back i {
    font-size: 24px;
    color: #5788ff;
}

.pageNum {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
}

.pageDesc {
    line-height: 21px;
}

.next {
    white-space: nowrap;
}

.next[class*='bordered'],
.next[class*='default'] {
    padding: 9px 32px;
    height: auto;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
}

.next[class*='bordered'] {
    padding: 8px 32px;
}
