.root {
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    max-width: 640px;
}

.header {
    display: flex;
    gap: 16px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #dbdce0;
}

.content {
    padding: 16px;
}

.closeButton + .header,
.closeButton + .content {
    padding-right: 44px;
}

.root label {
    height: unset;
}

@media (max-width: 767px) {
    .mobileFullscreen {
        padding: 0;
    }

    .mobileFullscreen .root {
        width: 100%;
        height: 100%;
        max-width: none !important;
        max-height: none;
        border-radius: 0;
    }

    .mobileFullscreen .content {
        overflow: scroll;
        flex-grow: 1;
    }
}

.footer {
    background-color: white;
    border-top: 1px solid #dbdce0;
    padding: 16px;
}
