@value border, textLight from '@advanza/css/vars.css';

.flexTable {
    border-spacing: 0;
    width: 100%;
}
.flexTable th {
    text-align: left;
    color: #5f6368;
    font-weight: 500;
}
.flexTable th,
.flexTable td {
    padding: 8px 5px;
}
.clickable {
    cursor: pointer;
}

.tableColExpand {
    padding: 0 !important;
}
.tableColExpand.expanded {
    background-color: #fafafa;
    box-shadow:0 6px 22px rgba(0, 0, 0, 0.15);
    /*border                     : 1px solid border;*/
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top: none;
}
.tableColExpand.expanded > div {
    position : relative;
}

.tableColExpand.expanded.noShadow {
    box-shadow: none;
    /*background-color: #fff;*/
}
.tableColExpand .expandButton {
    width: 50px;
}
.expandButton:hover i {
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
}

.rowLoading td {
    padding: 0 !important;
    position: relative;
}
.rowLoading td > div {
    top: -2px;
    margin-bottom: -2px;
}

@media screen and (max-width: 800px) {
    .flexTable tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0.5em 0;
        border: 1px solid border;
        border-radius: 7px;
    }
    .flexTable td,
    .flexTable th {
        flex: 1 1 150px;
    }
}
.th {
    box-shadow: 0 1px 0 border;
}
.list {
}
.list .listItem {
    position: relative;
}
.list .listItem:not(:last-child) {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}
.list .listItem.expandOnClick .expandButton i {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
}
.list .listItem.expanded {
    background-color: #fafafa;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}
.list .listItem.expanded td {
    border-top: 1px solid border;
}
.list .listItem.selected {
    background-color: rgba(64, 134, 250, 0.2);
}
