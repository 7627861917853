.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bgImg {
    position: relative;
    border-radius: 5.3px;
    border: 1px solid #c0c1c7;
}

.bgImg.withError {
    border-color: #f00;
}

.editBtn {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 2;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: #10185a;
    cursor: pointer;
}

.bgImg .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 5.3px;
    background-color: rgb(0, 0, 0, 0.65);
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    cursor: pointer;
}

.bgImg:hover .overlay {
    opacity: 1;
}

.bgImg .overlay span {
    position: relative;
    color: #fff;
}

.bgImg .overlay span::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: #fff;
}

.modalContent {
    background-color: #f7f7f7;
    padding: 0 24px;
}

.modalFooter {
    background-color: #fff;
    border-top: 1px solid #dbdce0;
    padding: 0 24px;
}

.errorMsg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5.3px;
    background-color: rgb(255, 255, 255, 0.65);
    padding: 5px;
}

.errorMsg span {
    color: #f00;
    font-size: 11px;
}

@media (min-width: 1024px) {
    .errorMsg span {
        font-size: 14px;
    }
}
