.row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 40px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
