.quote {
    color: #10185a;
}
.more {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #b6b9cd;
}
.more.link {
    padding-left: 2em;
    cursor: pointer;
    text-decoration: underline;
}
.more.ellipsis {
    padding-left: 1em;
}
.lessButton {
    margin-top: 16px;
}
.lessButton.alt {
    text-decoration: underline;
}
.lessButton .content {
    position: relative;
    color: #5788ff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.lessButton .content::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 4px;
    height: 1px;
    background-color: #5788ff;
}
.less br {
    display: none;
}
.less > span:first-of-type > span:after {
    content: ' ';
}
