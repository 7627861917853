.root {
    position: relative;
}
.new {
    border-radius: 5px;
    box-shadow: 0 2px 17px 0 rgba(182, 185, 205, 0.32);
    padding: 16px;
}
.item {
    padding: 6px 0;
    flex-wrap: nowrap;
    position: relative;
    transition: opacity 0.2s ease;
}
.saving {
    opacity: 0.3;
}
.old {
    text-decoration: line-through;
}
.active {
    color: mediumseagreen;
}
.delete {
    margin-right: 0;
    margin-left: auto;
}
.drop {
    cursor: pointer;
    height: 70px;
    border: 2px dashed #dadada;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.warning {
    color: #f46511;
}
.editNameBtn {
    padding: 0 4px;
    text-align: left;
}
