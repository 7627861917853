.root h1,
.root h2,
.root h3,
.root h4,
.root h5 {
    font-family: Poppins, Mulish, sans-serif;
}

.root {
    background-color: #fafafa;
    min-height: 100vh;
}
