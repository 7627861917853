@value primary, primaryBright, neutralsDarkLighter from './vars.css';

.checkbox label {
    cursor: pointer;
}

.checkbox label input {
    display: none;
}

.checkbox label i {
    font-size: 20px;
    color: primary;
}

.checkbox.checked label i {
    color: primaryBright;
}

.checkbox.disabled label {
    cursor: not-allowed;
}

.checkbox.disabled label i {
    color: neutralsDarkLighter;
}
