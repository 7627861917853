.body,
.textArea textarea {
    font-weight: 600;
    font-size: 14px;
}

.root {
    margin-bottom: 16px;
    display: flex;
}

.root > div:first-of-type {
    min-width: 39px;
    margin-right: 6px;
    text-align: center;
}

.cancelButton {
    margin-right: 10px;
}

.avatar {
    width: 39px;
    height: 39px;
    border-radius: 50%;
}

.avatar.hasImage {
    transition: transform 0.2s ease 1s;
    cursor: zoom-in;
}

.avatar.hasImage:hover {
    transform: scale(2.9);
}
.small {
    opacity: 0.7;
    display: inline-block;
    padding-top: 4px;
}

.showOnHover {
    display: none;
}

.root:hover .showOnHover {
    display: flex;
    margin-right: 12px;
    margin-left: auto;
}

.pinned {
    background-color: rgba(255, 227, 0, 0.2);
    padding: 12px 0px;
    border-radius: 5px;
}
.lastPinned {
    border-bottom: 1px solid rgb(221, 201, 40);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.sentiment_very_dissatisfied.pinned {
background-color: rgba(255, 0, 30, 0.2); ;
}
