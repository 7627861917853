.table thead td {
    max-width: 115px;
    vertical-align: top;
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table {
    border-collapse: collapse;
}
.table td {
    border: 1px solid #dadada;
    padding: 7px;
    text-align: right;
}
.sortable {
    cursor: pointer;
}
.table td a {
    text-decoration: underline;
    color: #0f61c7;
}
td.even {
    background-color: #eee;
}

.table tr:hover td,
.table tr:active td,
.table tr:focus td {
    background-color: #ecf3fc;
}

.openedRow td {
    background-color: #e1e4f2;
}

.openedRow td.even {
    background-color: #ccc;
}

.subRow td {
    background-color: #f9f9f9;
}

.subRow td.even {
    background-color: #ddd;
}

.posPercent {
    font-weight: bold;
}

.table2 tr > td:nth-child(n + 2):nth-child(-n + 5) {
    background-color: #a2dc94;
}
.table2 tr > td:nth-child(n + 6):nth-child(-n + 8) {
    background-color: #ffffad;
}
.table2 tr > td:nth-child(n + 9):nth-child(-n + 11) {
    background-color: #ffd059;
}
.table2 tr > td:nth-child(n + 12):nth-child(-n + 14) {
    background-color: #ff9b9b;
}
