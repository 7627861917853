.root {
    position: relative;
    width: 276px;
    background: rgb(255, 255, 255);
    border: 0px solid rgba(0, 0, 0, 0.25);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    border-radius: 4px;
    padding: 12px;
}

.triangleBg {
    position: absolute;
    top: -11px;
    left: 12px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 9px 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.1);
}

.triangle {
    position: absolute;
    top: -10px;
    left: 12px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 9px 10px;
    border-color: transparent transparent rgb(255, 255, 255);
}

.grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 6px;
}

.swatch {
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-radius: 4px;
}

.input {
    grid-column: span 4;

    > label > input {
        padding: 0 !important;
        height: 30px !important;
    }
}
