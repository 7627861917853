.header,
.item {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    padding: 6px 14px;
    grid-column-gap: 14px;
}

.item {
    transition: background-color 0.16s ease;
    border-top: 1px solid #dadada;
}
