.labelWrapper {
    display: inline-block;
}

.label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 5px;
    border-radius: 15px;
    line-height: 1;
    margin: 6px 0;
}

.label i {
    color: inherit;
    margin-right: 4px;
    transform: none !important;
}

.label span {
    display: inline-block;
    font-size: 0.75rem;
}

.labelFilterSelect {
    max-width: 250px;
}
