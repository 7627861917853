@value primaryBright, primaryLightest2, neutralsLightDarker from './vars.css';

.appearance_blocks {
    .block {
        padding: 8px 12px !important;
        border-radius: 5px;
        border: 1px solid neutralsLightDarker;
        background: white;

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        &.selected {
            border-color: primaryBright;
            background: primaryLightest2;
        }
    }
}
