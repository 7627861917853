.root {
    position: relative;
    display: flex;
}

.root.wide,
.wide .input {
    display: block;
}

.legend {
    font-family: Mulish, sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: primary;
    margin: 2px 0 4px;
}

.input {
    border: 1px solid #b6b9cd;
    border-radius: 4px;
    align-items: center;
    background-color: #fff;
    font-size: 16px;
    line-height: 32px;
    display: inline-block;
    height: 36px;
    position: relative;
    padding: 0 36px 0 14px;
    cursor: pointer;
}

.input,
.input > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.empty .input {
    color: rgb(128, 134, 139);
}

.large:not(.empty) .input {
    border-color: #10185a;
}

.large .input {
    height: 46px;
    line-height: 42px;
}

.input.hasError {
    border-color: red;
}

.stickyHeaderAndSearch {
    position: sticky;
    top: -12px;
    background-color: white;
    margin-top: -12px;
    padding-top: 12px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 0 14px 0;
    min-width: 200px;
    top: 0;
    left: 0;
    width: 100%;
}

.done {
    text-align: right;
}

.done button {
    text-align: right;
    padding: 6px 12px 0;
}

.option {
    display: flex;
    align-items: center;
    padding: 7px 12px;
    cursor: default;
}

.option i {
    cursor: pointer;
    border-radius: 50%;
    margin-right: 4px;
    transition: 0.11s ease;
    color: #72758c;
    flex: 0 0 24px;
}

.option.checked i {
    color: #5788ff;
}
.option.disabled {
    pointer-events: none;
    color: grey;
}
.option.disabled i {
    color: grey;
}

.option i:hover {
    box-shadow: 0 0 0 7px rgba(16, 24, 90, 0.05);

    background-color: rgba(16, 24, 90, 0.05);
}
.option:focus {
    background-color: rgba(16, 24, 90, 0.05);
}

.enter {
    transform: scale(0);
    opacity: 0;
}

.enterActive {
    transform: scale(1);
    transition: transform 0.2s ease-out, opacity 0.2s ease-in 0.1s;
    opacity: 1;
}

.exitActive {
    transform: scale(0);
    transition: transform 0.2s ease-in 0.1s, opacity 0.1s ease-in;
    opacity: 0;
}

.picker {
    transform-origin: top left;
    z-index: 999;
    box-sizing: border-box;
    position: fixed;
    min-width: 200px;
    width: 100%;
    top: 0;
    left: 0;
    padding: 12px 0px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
    background-color: #fff;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.root.bottom .picker {
    top: auto;
    bottom: 0;
}
.selectTriangle {
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 33%;
    width: 9px;
    height: 9px;
    background: transparent;
    border-bottom: 2px solid #10185a;
    border-left: 2px solid #10185a;
    transform: rotate(-45deg);
}

.error {
    font-weight: bold;
    line-height: 1.5;
    color: red;
}
.picker input {
    margin: 0 12px;
    width: calc(100% - 24px);
    border-bottom: 1px solid #d2d2d2;
    color: #10185a;
}

@media (min-width: 768px) {
    .picker {
        width: auto;
        position: absolute;
        top: -12px;
        padding: 12px 0px 12px;
        left: -5px;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
    }
}

@media (max-width: 767px) {
    .picker {
        max-height: 100% !important;
    }
}
