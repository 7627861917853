.grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;
}

.item {
    border: 1px solid #dadce0;
    overflow: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
}

.item.clickable {
    cursor: pointer;
}

.imageName {
    word-break: break-all;
    padding-bottom: 10px;
}

/* where {column width} is usually fixed size
 * and {number of columns} is the maximum number of columns.
 * Additionally, to avoid breaks inside your elements, you want to add:
 */
.imageBlock {
    width: 100%;
    display: inline-block;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

.loading {
    opacity: 0.5;
    transition: 0.2s ease;
}

.imageBlock img {
    display: block;
    object-fit: scale-down;
    max-height: 500px;
}

.imgOverlay {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(87, 136, 255, 0.3);
}

.imgOverlay.selected,
.item.clickable:hover .imgOverlay {
    display: flex;
}

.title {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 8px;
    border-radius: 4px;
    background: #eff8ff;
    color: #5788ff;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}

.title i {
    display: none;
    color: #5788ff !important;
    font-size: 16px;
}

.item.clickable:hover .titleText {
    display: none;
}

.item.clickable:hover .title i {
    display: inline-block;
}

.imageResponseUrl {
    overflow-wrap: break-word;
    z-index: 10;
    background-color: #fff;
    margin: 0 -10px -10px;
    padding: 10px;
}
