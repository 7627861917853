.root > button {
    margin-right: 0;
    margin-left: auto;
    display: flex;
}
.warning {
    margin: 16px 4px;
    background-color: #c6a5a5;
    font-weight: bold;
}
