.reviewList {
    max-width: 500px;
    font-size: 14px;
}

.nameRow {
    font-weight: 600;
}

.nameRow b {
    font-size: 21px;
}

.comment {
    margin: 8px 0;
    background-color: #fafafa;
    border-left: 2px solid #dadada;
    padding: 10px;
}

.review {
    border: 1px solid #dadce0;
    border-radius: 8px;
    margin-top: 16px;
    padding: 24px 48px 12px 24px;
    position: relative;
    transition: all 0.16s ease;
}

.review small {
    display: block;
    color: #757575;
}

.review.loading {
    opacity: 0.6;
}

.review.selected {
    background-image: radial-gradient(circle at 50% 100%, #fff9f1, #ffe6c8 102%);
}

.body {
    white-space: pre-line;
}

.review p,
.comment {
    letter-spacing: 0.2px;
    color: #5f6368;
    line-height: 20px;
}
