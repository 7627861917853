.sticky {
    position: sticky;
    top: 0px;
    z-index: 999;
    background-color: #fafafa;
    margin: 0;
    padding: 14px 7px;
}

.questionRow {
    padding: 12px;
}

.questionStatus {
    min-width: 150px;
}

.questionType {
    width: 240px;
}

.invalidType {
    color: #cf3131;
}
