.root {
    display: inline-block;
    vertical-align: baseline;
    align-self: flex-end;
    font-size: 6px;
    white-space: nowrap;
    position: relative;
    bottom: 2px;
}

.root > span {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: #10185a;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    display: inline-block;
    vertical-align: middle;
}

.root > span:not(:last-child) {
    margin-right: 0.45em;
}

.root > span:first-child {
    animation-name: fade1;
}

.root > span:nth-child(2) {
    animation-name: fade2;
}

.root > span:nth-child(3) {
    animation-name: fade3;
}

@keyframes fade1 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-1.1em);
        transform: translateX(-1.1em);
    }
    16% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    32% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    48% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    64% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    80% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    96% {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
}

@keyframes fade2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-1.1em);
        transform: translateX(-1.1em);
    }
    16% {
        opacity: 0;
        -webkit-transform: translateX(-1.1em);
        transform: translateX(-1.1em);
    }
    32% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    48% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    64% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    80% {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
    96% {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
}

@keyframes fade3 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-1.1em);
        transform: translateX(-1.1em);
    }
    16% {
        opacity: 0;
        -webkit-transform: translateX(-1.1em);
        transform: translateX(-1.1em);
    }
    32% {
        opacity: 0;
        -webkit-transform: translateX(-1.1em);
        transform: translateX(-1.1em);
    }
    48% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    64% {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
    80% {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
    96% {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(1.1em);
        transform: translateX(1.1em);
    }
}
