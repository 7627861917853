.row {
    display: flex;
    justify-content: space-between;
}
.green {
    color: #4BB545;
}
.stats {
    padding: 14px 0;
}
.logs {
    font-size: 11px;
    max-height: 20em;
    overflow: auto;
}