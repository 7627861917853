.overlay {
    width: 100vw;
    height: 62vh;
    text-align: center;
    position: fixed;
    top: 0;
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
}

.wrapper {
    text-align: center;
    display: flex;
    -webkit-animation: start 0.3s ease-in;
    animation: start 0.3s ease-in;
}

.ldsEllipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 12px;
    margin: auto;
}

.ldsEllipsis div {
    position: absolute;
    top: 0;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #0077cc;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}

@-webkit-keyframes start {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 20px;
        opacity: 1;
    }
}
@keyframes start {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 20px;
        opacity: 1;
    }
}
@-webkit-keyframes end {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 2px;
        opacity: 1;
    }
}
@keyframes end {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 2px;
        opacity: 1;
    }
}
