body:has(dialog[open]) {
    overflow: hidden;
}
.root {
    border: none;
    border-radius: 9px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.root.groovy::backdrop {
    background-image: linear-gradient(
            45deg,
            #10185a,
            #5788ff,
            #10185a
    );
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
/* State of the backdrop when the dialog is displayed */
.root[open]::backdrop {
    opacity: 0.75;
    transition: opacity 0.5s ease-in-out;
}

.root > button.close {
    display: flex;
    padding: 4px;
    font-size: 19px;
    margin: -0.5em 0 -1em auto;
}

@media(min-width: 768px) {
    .root {
        max-width: 100%;
        width: 500px
    }
}
