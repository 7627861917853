.image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    min-height: 165px;
    width: 100%;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.add {
    font-size: 19px;
    font-weight: 800;
}

.edit {
    display: block;
    width: 100%;
    padding: 5px 0;
    background-color: rgba(0, 0, 0, 0.5);
    text-shadow: 0px 0px 4px #000;
    align-self: flex-end;
}
