.root {
    width: 100%;
    display: flex;
}

.root :global(.rdrCalendarWrapper) { 
    max-width: 100%;
}

.root :global(.rdrMonths) {
    flex-wrap: wrap;
    justify-content: center;
} 

.root select {
    height: auto;
}

@media (max-width: 600px) {
    .root {
        flex-wrap: wrap;
    }
    
    .root :global(.rdrDefinedRangesWrapper) {
        width: 100%;
        border-right: none;
    }
    
    .root :global(.rdrStaticRange), .root :global(.rdrInputRange) {
        display: flex;
        justify-content: center;
    }
}
