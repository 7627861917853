.settingsRow {
    display: inline-flex;
    grid-gap: 16px;
}

.resultsRow {
    display: flex;
    grid-gap: 16px;
    transition: opacity 0.2s;
}
.resultsRow > div:last-of-type {
    width: 375px;
}
.loading {
    opacity: 0.5;
}
.resultsRow table,
.resultsRow tbody {
    width: 100%;
}
.resultsRow table {
    display: block;
}
.resultsRow table td {
    padding: 6px;
    border-bottom: 1px solid #dadada;
}
