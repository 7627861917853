.dialogEditDay {
    .dateInputWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 8px;

        .dateInput {
            width: 100%;

            label {
                input {
                    padding: 8px 8px 8px 12px;
                }

                > div:last-of-type {
                    // inputField after
                    padding-right: 8px;
                }
            }
        }
    }

    .repeatButtonWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .errorMessage {
        color: #f42435;
    }
}
