.root {
    background-color: rgba(16, 24, 90, 0.4);
}

.modalInside {
    max-width: 800px;
    height: calc(100% - 150px);
    overflow: visible;
    box-shadow: none;
    background-color: transparent;
}

.closeButton {
    top: -42px;
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.closeButton button {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0;
}

.closeButton button i {
    color: #10185a;
    font-size: 24px !important;
}

.content {
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content > div:first-child {
    flex-grow: 1;
}

.legend {
    background-color: white;
    padding: 14px 16px;
    font-size: 14px;
}

.legend > div {
    padding-top: 5px;
    padding-bottom: 5px;
}

.noWrap {
    white-space: nowrap;
}

.lowRateColor,
.highRateColor {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    opacity: 0.4;
}

.lowRateColor {
    background-color: #327bfe;
}

.highRateColor {
    background-color: #0000ff;
}
